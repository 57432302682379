<template>
  <div>
     <FilterWithChips
      @selected-option="handleSelectedOption"
      :selection="getSiteNames()"
      icon-one-name="['fab', 'map-marker-alt']"
     
    />
      <div v-if="availableLocations.size > 0" class="pr-6 pl-6" >
        <div
          v-for="[key, value] in availableLocations"
          :key="key"
        >
          <v-row>
            <v-col class="pt-5">
              <h3>
                Standort {{ key }}
              </h3>
            </v-col>
          </v-row>

          <v-row
            v-for="(gap, index) in isExpanded[key]
              ? value
              : value.slice(0, 3)"
            :key="index"
          >
            <v-col
              class="pt-0 pb-5 cursor-pointer"
              @click="bookGap(gap, index)"
            >
              <BookingOfferBox
                :key="index + gap.modelId"
                v-ripple
                :booking-summary="
                  summary(
                    gap.startDate,
                    gap.endDate,
                    gap.siteId,
                    gap.modelId,
                    gap.modelVariantId
                  )
                "
                :original-end-date="null"
                :price="gap.rentPrice"
                :price-on-sale="gap.discountPercentage"
                :booking-days="gap.bookingDays"
                :included-kms="gap.includedKms"
              />
            </v-col>
          </v-row>
          <v-row v-if="!isExpanded[key] && value.length > 3">
            <v-col class="pt-0">
              <div class="pt-6">
                <v-btn
                  style="zIndex: 7"
                  block
                  @click="clickHandler(key)"
                >
                  {{
                    $vuetify.breakpoint.width > 400
                      ? $t("rentGapsPage.showMore")
                      : $t("rentGapsPage.more")
                  }}
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </div>
      </div>
      <div
        id="recaptcha"
        data-size="invisible"
        :data-sitekey="captchaWebsiteKey"
        style="position: fixed; top: 100px;"
      />
      <div v-if="availableLocations.size == 0" class="pr-6 pl-6" >
        <v-card
          class="no-options-available"
          subtitle="The #1 Vue UI Library"
          width="parent"
        >
          <v-card-text class="bg-surface-light pt-4" prepend-icon="fa-map-marker">
          {{ $t("rentGapsPage.noOptions") }}
          </v-card-text>
        </v-card>
      </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import BookingOfferBox from "../alternatives/BookingOfferBox";
import FilterWithChips from "@/components/ui/FilterWithChips"
import {router} from "@/router";

export default {
  components: {
    BookingOfferBox,
    FilterWithChips,
  },
  props: {
    fullWidth: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isBookable: false,
    marginBtm: 30,
    pddngLft: 30,
    mrginTop: 40,
    isExpanded: {},
    selectedLocation: "",
    availableLocations: {},
    showAllLocations: true, 
  }),

  async beforeMount() {
    await this.LOAD_GAPS_DATA();
    this.availableLocations = this.sortedGaps();
  },
  methods: {
    renderCaptcha() {
      this.loadingCaptchaInput = true;
      try {
        // "check" if reCaptcha is already/still rendered:
        window.grecaptcha.reset();
      } catch (err) {
        window.grecaptcha.render("recaptcha", {
          callback: this.verifyCaptcha,
          hl: this.$i18n.locale,
        });
      }

      // observe reCaptcha-window and reenable forward-button when it is closed:
      window.grecaptcha.execute().then(() =>
        setTimeout(() => {
          let closeWaiter = setInterval(
            function () {
              const reCaptchaIframes = document.querySelectorAll(
                'iframe[src*="recaptcha/api2/bframe"]'
              );
              const iframe = reCaptchaIframes[reCaptchaIframes.length - 1];
              if (
                iframe &&
                iframe.parentNode.parentNode.style.visibility == "hidden"
              ) {
                clearInterval(closeWaiter);
                this.loadingCaptchaInput = false;
              }
            }.bind(this),
            100
          );
        }, 2000)
      );
    },
    async verifyCaptcha(captchaToken) {
      this.loadingCaptchaVerification = true;
      try {
        const isCaptcha = await this.POST_CAPTCHA(captchaToken);
        if (isCaptcha) {
          // if (this.isAvailable) {
          const selectedGapOffer = this.gaps[this.chosenOfferIndex];
          var selectedCar = {
            modelId: selectedGapOffer.modelId,
            modelName: selectedGapOffer.modelName,
            belongsTo: selectedGapOffer.belongsTo, 
            siteId: selectedGapOffer.siteId,
            equipmentLineId: "",
            modelVariantId: selectedGapOffer.modelVariantId,
            startDate:  selectedGapOffer.startDate,
            endDate: selectedGapOffer.endDate,
          };
          var bookingData = {
            baseBooking: {
              endDate: selectedGapOffer.endDate,
              equipmentLineId: "",
              modelId: selectedGapOffer.modelId,
              belongsTo: selectedGapOffer.belongsTo,
              modelVariantId: selectedGapOffer.modelVariantId,
              siteId: selectedGapOffer.siteId,
              siteName: selectedGapOffer.siteName,
              startDate: selectedGapOffer.startDate,
              vehicleId: selectedGapOffer.vehicleId,
            },
            bookingDetails: {
              bookingOptionIds: null,
              handoverTimeId: null,
              returnTimeId: null,
            },
            pickupTimes: {
              availableHandoverIds:  selectedGapOffer.pickupTimes.availableHandoverIds,
              availableReturnIds: selectedGapOffer.pickupTimes.availableReturnIds,
              defaultHandoverTimeId: selectedGapOffer.pickupTimes.defaultHandoverTimeId,
              defaultReturnTimeId: selectedGapOffer.pickupTimes.defaultReturnTimeId,
            }
          };
         
          await this.SET_LAST_GAP_SEEN(bookingData);
          await this.SAVE_SELECTION_FORM_DATA(selectedCar);
          this.RESET_SELECTED_PICKUP_TIMES();
          
          await this.CHECK_AVAILABILITY(true)
          router.navigateTo("details");
        }
      } catch (err) {
        this.UPDATE_ERROR({ headline: "captchaValidationFailed", error: err });
        router.navigateTo("error");
      }
      this.loadingCaptchaVerification = false;
    },
    clickHandler(k) {
      this.isExpanded = {[k]: true, ...this.isExpanded};
    },
    summary(startDate, endDate, siteId, modelId, modelVariantId) {
      return {
        startDate: startDate,
        endDate: endDate,
        modelId: modelId,
        siteName: this.outputSiteName(siteId),
        modelName: this.models.find((elem) => elem.modelId === modelId)?.name,
        modelVariantName: this.models.find(
          (elem) =>
            elem.modelVariants.filter(
              (obj) => obj.modelVariantId === modelVariantId
            )?.name
        ),
        equipmentLineName: "",
      };
    },
    ...mapActions({
      LOAD_GAPS_DATA: "LOAD_GAPS_DATA",
      POST_CAPTCHA: "POST_CAPTCHA",
    }),
    ...mapMutations({
      UPDATE_ERROR: "UPDATE_ERROR",
      SET_LAST_GAP_SEEN: "SET_LAST_GAP_SEEN",
      RESET_SELECTED_PICKUP_TIMES: "RESET_SELECTED_PICKUP_TIMES",
    }),
    selectOffer(index) {
      router.navigateTo("details", {id: index});
    },
    outputSiteName(siteId) {
      return this.sites.find((elem) => elem.siteId === siteId)?.name;
    },
    ...mapActions({
      CHECK_AVAILABILITY: "CHECK_AVAILABILITY",
      SAVE_SELECTION_FORM_DATA: "SAVE_SELECTION_FORM_DATA",
    }),
    async bookGap(rentGap) {
      await this.renderCaptcha();

      //Gets the position of the offer in the original array
      var count = 0
      this.gaps.forEach((gap) => {
        if(gap.modelId == rentGap.modelId && gap.startDate == rentGap.startDate && gap.endDate == rentGap.endDate){// verify it is the same car
          this.chosenOfferIndex = count
        }
        count++
      });
    },
    sortedGaps() {
      let sortedMap = new Map();
      this.gaps.forEach((gap) => {
        let siteName = gap.siteName;
        if (siteName == this.selectedLocation || this.showAllLocations){
          if (sortedMap.has(siteName)) {
            const gapArray = sortedMap.get(siteName);
            gapArray.push(gap);
            gapArray.sort((a,b) => {
              
              if (a.startDate < b.startDate) {
                return -1
              }
              if (a.startDate > b.startDate) {
                return 1
              }
              return 0
            })

            sortedMap.set(siteName, gapArray);
          } else {
            sortedMap.set(siteName, new Array(gap));
          }
        }
      });
      const alphabetclSorted = new Map([...sortedMap].sort());
      return alphabetclSorted;
    },
    getSiteNames() {
      let siteArray = [];
      siteArray.push(this.$t('rentGapsPage.showAll'))
      this.sites.forEach((site) => {
        siteArray.push(this.outputSiteName(site.siteId))
      });
      return siteArray.sort()
    },
    handleSelectedOption(payload) {
      this.selectedLocation = payload;
      //if all sites are chosen
      if (this.selectedLocation == this.getSiteNames()[0]){
        this.showAllLocations = true;
      }else{
        this.showAllLocations = false;
      }
      this.availableLocations = this.sortedGaps()
    },
  },
  computed: {
    ...mapGetters({
      gaps: "gaps",
      sites: "sites",
      models: "models",
      isAvailable: "isAvailable",
      captchaWebsiteKey: "captchaWebsiteKey",
    }),
  },
  
};
</script>

<style scoped>
.no-options-available{
  margin-top: 10px;;
}
</style>