<template>
  <div class="booking-summary-box">
    <v-card v-if="bookingSummary">
      <v-card-text>
        <v-row>
          <v-col
            :cols="$vuetify.breakpoint.width > 600 ? 9 : 12"
            class="pt-0 pb-0"
          >
            <div class="body-2">
              <p class="box-headline">
                <span class="heading">{{ chosenCarName }}</span>
              </p>
              <p class="mb-1">
                <i18n path="bookingSummaryBox.offerDate">
                  <template #duration>
                    <strong>{{
                      $tc("bookingSummaryBox.days", bookingDays, {
                        days: bookingDays,
                      })
                    }}</strong>
                  </template>

                  <template #start>
                    <strong>
                      <DateDisplay :date="bookingSummary.startDate" />
                    </strong>
                  </template>

                  <template #end>
                    <strong>
                      <DateDisplay :date="originalEndDate ? originalEndDate : bookingSummary.endDate" />
                    </strong>
                  </template>
                </i18n>
              </p>
              <p class="mb-3">
                {{ $t("bookingSummaryBox.handoverAndReturn") }}:
                <strong>{{ bookingSummary.siteName }}</strong>
              </p>
              <p
                v-if="priceOnSale"
                class="mb-3"
              >
                <s>ab {{ $n(price/(1-(priceOnSale/100)) , "currency") }}</s>
              </p>
              <p
                v-if="priceOnSale"
                class="mb-2"
              >
                <i18n path="bookingSummaryBox.offerPriceKm">
                  <template #price>
                    ab <strong><span class="price">{{ $n(price, "currency") }}</span></strong>
                  </template>
                  <template #km>
                    <strong>
                      {{
                        $t("bookingSummaryBox.freeDistance", [includedKms])
                      }}</strong>
                  </template>
                </i18n>
              </p>
              <p
                v-else
                class="mb-2"
              >        
                <i18n path="bookingSummaryBox.offerPriceKm">
                  <template #price>
                    <strong>
                      <span v-if="isMonthlyPrice()" class="price">{{ $n(monthlyPrice, "currency") }} </span>
                       <span v-else class="price">{{ $n(price, "currency") }}</span>
                    </strong>
                    <span  v-if="isMonthlyPrice()" >/ Monat</span>
                        <br>
                  </template>
                  <template #km>
                  
                          <strong>
                            {{
                              $t("bookingSummaryBox.freeDistance", [
                              isMonthlyPrice() ? Math.round((includedKms / bookingDays)*month) : includedKms
                              ])
                            }}</strong>
                  </template>
                </i18n>
              </p>
              <div 
              v-if="priceOnSale"
              class="sale-price-tag"
            >
              <div
              class="pt-1 pl-3">
                <span>{{Math.round((priceOnSale))}}%</span>
              </div>
            </div>
            </div>
          </v-col>

          <v-col
            cols="3"
            class="car-avatar pt-5"
            style="zIndex: 6"
          >
            <key-image
              :model-id="bookingSummary.modelId"
              class=" hover-car-avatar"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import DateDisplay from "../../ui/DateDisplay";
import KeyImage from "../../ui/KeyImage";

export default {
  components: {
    DateDisplay,
    KeyImage,
  },
  data() { 
    return {
      month: 28,
      twoMonths :56
    }
  },

  props: {
    bookingSummary: { type: Object, default: null },
    priceOnSale: { type: Number, default: null },
    price: { type: Number, default: null },
    bookingDays: { type: Number, default: null },
    includedKms: { type: Number, default: null },
    originalEndDate: { type: String, default: null },
    monthlyPrice: { type: Number, default: null },
  },

  computed: {
    chosenCarName() {
      let str = this.bookingSummary.modelName;
      if (this.bookingSummary.modelVariantName)
        str += ", " + this.bookingSummary.modelVariantName;
      return str;
    },
  },
  methods: {
    isMonthlyPrice() {
      if (this.bookingDays > this.twoMonths){
        return true;
      }
      return false;
    },
  }
};
</script>
