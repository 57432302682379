<template>
  <v-row :dense="fullWidth">
    <v-col
      v-for="alert in alerts"
      :key="alert.title"
    >
      <v-alert
        dense
        outlined
        class="mb-0"
        :type="alert.type"
      >
        {{ $t(alert.title) }}
        <br>
        <span class="hint">
          {{ $t(alert.subtitle) }}
        </span>
      </v-alert>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    alerts: {
      type: Array,
      required: true,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    }
  }
};
</script>