<template>
  <CenterContainer>
    <v-row
      justify="center"
      align="center"
      class="items"
    >
      <v-col
        v-for="(car, index) in masterDataLoading ? 10 : models"
        :key="index"
        class="show-cars"
        align="center"
        justify="center"
      >
        <ModelBanner v-if="masterDataLoading" />
        <ModelBanner
          v-else
          :model-to-display="car"
        />
      </v-col>
    </v-row>
  </CenterContainer>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import CenterContainer from "@/components/ui/CenterContainer";
import ModelBanner from "@/components/ui/ModelBanner.vue";
import { router } from "@/router";

export default {
  components: {
    CenterContainer,
    ModelBanner,
  },  
  async beforeMount() {
    if (this.masterDataError) {
      this.UPDATE_ERROR({ error: "backend.noMasterData" });
      router.navigateTo("error");
    }
  },
  computed: {
    ...mapGetters({
      models: "models",
      masterDataLoading: "masterDataLoading",
      masterDataError: "masterDataError",
    }),
  },
  methods: {    
    ...mapMutations({
      UPDATE_ERROR: "UPDATE_ERROR",
    }),
  }
};
</script>