<template>
  <v-row>
    <v-col
      cols="6"
      class="pt-0"
    >
      <button
        type="button"
        block
        color="white"
        class="
          font-weight-regular
          toggle-button
          mobile-responsive
          text-none
        "
        :class="{ active: !value }"
        @click="$emit('input', false)"
      >
        {{ $t("contactForm.customerType.person") }}
      </button>
    </v-col>

    <v-col
      cols="6"
      class="pt-0"
      style="z-index: 10"
    >
      <button
        type="button"
        color="white"
        block
        class="
          font-weight-regular
          toggle-button
          mobile-responsive
          text-none
        "
        :class="{ active: value }"
        @click="$emit('input', true)"
      >
        {{ $t("contactForm.customerType.company") }}
      </button>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    value: { type: Boolean },
  },
};
</script>
