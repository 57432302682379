<template>
  <v-form
    ref="driversForm"
    v-model="isValid"
    class="drivers-input"
  >
    <v-row
      v-for="driver in numberOfDrivers"
      :key="driver"
      class="mb-3"
    >
      <v-col
        cols="12"
        class="pb-0 font-weight-medium body-2"
      >
        {{ $t("driversInput.headline", [driver]) }}
      </v-col>

      <v-col cols="6">
        <v-text-field
          v-model="driversForm[driver - 1].firstname"
          outlined
          :label="$t('contactForm.firstName')"
          :name="'firstName' + driver"
          :rules="validateField('firstName' + driver)"
          :error-messages="$t(validationErrors[driver - 1].firstname)"
          required
          hide-details="auto"
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="driversForm[driver - 1].lastname"
          outlined
          :label="$t('contactForm.lastName')"
          :name="'lastName' + driver"
          :rules="validateField('lastName' + driver)"
          :error-messages="$t(validationErrors[driver - 1].lastname)"
          required
          hide-details="auto"
        />
      </v-col>

      <v-col cols="6">
        <BirthdayInput 
          v-model="driversForm[driver - 1].birthdayInput"          
          :rules="validateField('birthDay' + driver)"
          :error-message="$t(validationErrors[driver - 1].birthday, [validationErrors[driver - 1].birthdayPlaceholderData])"
          :missing-booking-option-id="validationErrors[driver - 1].missingBookingOptionId"
          :name="'birthDay' + driver"
          @update:loading="loading = true"
        />
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import { parseBirthday } from "@/utils";
import BirthdayInput from "./BirthdayInput.vue"
import axios from "axios";
import { bus } from "@/main";

export default {
  components: {
    BirthdayInput
  },
  props: {
    numberOfDrivers: {
      type: Number,
      required: true,
    },
  },

  data: () => ({
    driversForm: [{}, {}, {}],
    isValid: false,
    validationErrors: [{}, {}, {}],
    validationRunning: false,
    validationQueued: false,
    typingTimer: null,
    waitingForValidationSince: null,
    loading: false
  }),
  watch: {
    isValid() {
      this.$emit("update:validation", this.isValid);
    },
    loading() {
      this.$emit("update:loading", this.loading);
    }
  },
  async beforeMount() {
    if (this.additionalDrivers) {
      this.driversForm = this.additionalDrivers;
    }
  },
  mounted() {
    bus.$on("disabledForwardButtonClicked", () => {
      this.$refs.driversForm.validate();
    });
    if (this.additionalDrivers) {
      this.$refs.driversForm.validate();
    }
  },
  computed: {
    ...mapGetters({
      additionalDrivers: "additionalDrivers",
      baseBooking: "baseBooking",
      bookingDetails: "bookingDetails"
    })
  },
  methods: {
    ...mapMutations({
      SET_ADDITIONAL_DRIVERS: "SET_ADDITIONAL_DRIVERS",
    }),
    parseInputData() {
      var result = [];

      this.driversForm.forEach((x) => {
        var y = {};
        Object.assign(y, x);
        y.birthday = x.birthdayInput ? parseBirthday(x.birthdayInput) : "";
        result.push(y);
      });

      return result;
    },
    validateField(fieldName) {
      return [
        (v) => {
          let clientValidationError = this.validateClientSide(v);

          if (!clientValidationError) {
            this.loading = true;
            clearTimeout(this.typingTimer);
            if (!this.waitingForValidationSince) {
              this.waitingForValidationSince = new Date().getTime();
            }

            if (new Date().getTime() - this.waitingForValidationSince > 2000) {
              this.validateServerSide();
            } else {
              this.typingTimer = setTimeout(this.validateServerSide, 700);
            }
          } else if (this.validationErrors[fieldName]) {
            this.validationErrors[fieldName] = clientValidationError;
          }

          return !clientValidationError || this.$t(clientValidationError);
        },
      ];
    },
    validateServerSide() {
      if (this.validationRunning) {
        this.validationQueued = true;
      } else {
        this.validationRunning = true;
        this.validationQueued = false;
        this.waitingForValidationSince = null;
        var requestData = {
          belongsTo: this.baseBooking.belongsTo, // In case lead has no organization assigned to it yet
          additionalDrivers: this.parseInputData(this.driversForm),
          bookingStart: this.baseBooking.startDate,
          modelId: this.baseBooking.modelId,
          variantId: this.baseBooking.modelVariantId,
          bookedOptionIds: this.bookingDetails.bookingOptionIds
        };

        axios
          .post("/validation/drivers", requestData)
          .then((result) => {
            this.validationErrors = result.data;
            this.loading = false;
          })
          .finally(() => {
            this.validationRunning = false;
            if (this.validationQueued) {
              this.validateServerSide();
            } else {
              this.SET_ADDITIONAL_DRIVERS(requestData.additionalDrivers);
            }
          });
      }
    },
    validateClientSide(value) {
      return !value ? "general.validation.mandatory" : "";
    },
  },
  destroyed() {
    bus.$off("disabledForwardButtonClicked");
  },
};
</script>