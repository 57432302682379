<template>
  <CenterContainer class="lead-submission-page">
    <v-row>
      <v-col
        class="pb-5"
      >
        <v-card>
          <v-card-title
            class="font-weight-medium subtitle-2"
          >
            {{ $t("leadSubmissionPage.headline") }}
          </v-card-title>
          <v-card-subtitle>
            <i18n path="leadSubmissionPage.message">
              <template #termsOfServiceLink>
                <a
                  :href="$t('general.url.termsOfService')"
                  target="_blank"
                  @click.stop
                >{{ $t("termsAndConditions.linkText.termsOfService") }}</a>
              </template>

              <template #privacyPolicyLink>
                <a
                  :href="$t('general.url.privacyPolicy')"
                  target="_blank"
                  @click.stop
                >{{ $t("termsAndConditions.linkText.privacyPolicy") }}</a>
              </template>
            </i18n>
          </v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>

    <BookingSummaryBox
      :booking-summary="selectionSummary"
      :booking-days="calculationResults.bookingDays"
      :display-price-and-free-km="false"
    />

    <v-row
      v-if="customNote"
      class="mt-3"
    >
      <v-col
        class="pb-5"
      >
        <v-card>
          <v-card-title class="font-weight-medium subtitle-2">
            {{ $t("leadSubmissionPage.customNote") }}:
          </v-card-title>
          <v-card-subtitle>
            <div class="custom-note">
              {{ customNote }}
            </div>
          </v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>

    <v-row classa="pt-5">
      <v-col
        class="pb-5 pt-10"
      >
        <NavigationButtons
          :navigate-back-action="navigateBack"
          :back-enabled="!loading"
          :navigate-forward-action="navigateForward"
          :error-action="onForwardError"
          :requires-captcha="true"
          forward-text-key="general.button.submitLead"
        />
      </v-col>
    </v-row>
  </CenterContainer>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import CenterContainer from "@/components/ui/CenterContainer";
import NavigationButtons from "@/components/ui/NavigationButtons";
import BookingSummaryBox from "@/components/ui/BookingSummaryBox";
import { mapGetters } from "vuex";
import { router } from "@/router";

export default {
  components: {
    CenterContainer,
    NavigationButtons,
    BookingSummaryBox,
  },
  computed: {
    ...mapGetters({
      selectionFormData: "selectionFormData",
      contactData: "contactData",
      calculationResults: "calculationResults",
      customNote: "customNote",
    }),
    selectionSummary() {
      // omitt variant and equipment, because they are not part of the lead-data:
      const summaryData = {
        startDate: this.selectionFormData.startDate,
        endDate: this.selectionFormData.endDate,
        modelName: this.selectionFormData.modelName,
        siteName: this.selectionFormData.siteName,
        modelId: this.selectionFormData.modelId,
      };
      return summaryData;
    },
  },
  async beforeMount() {
    if (!this.selectionFormData || !this.contactData) {
      router.navigateTo("vehicleSearch");
    }
  },
  data: () => ({
    loading: false,
    isValid: false,
  }),
  methods: {
    ...mapActions({
      SUBMIT_LEAD: "SUBMIT_LEAD",
    }),
    ...mapMutations({
      UPDATE_ERROR: "UPDATE_ERROR",
      SET_SUBMISSION_SUCCESS: "SET_SUBMISSION_SUCCESS",
    }),
    async navigateForward() {
      this.loading = true;
      await this.SUBMIT_LEAD();
      this.loading = false;
      this.SET_SUBMISSION_SUCCESS(true);
      router.navigateTo("thankYou");
    },
    navigateBack() {
      router.navigateTo("leadContact");
    },
    onForwardError(err) {
      this.UPDATE_ERROR({ error: err, buttonRoute: "leadContact" });
      router.navigateTo("error");
    },
  },
};
</script>
