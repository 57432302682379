<template>
  <v-row
    class="booked-option-line"
    dense
  >
    <v-col :cols="showPrice ? 9 : 12">
      + {{ bookedOption(optionId).name }}
    </v-col>
    <v-col
      v-if="showPrice"
      cols="3"
      class="option-price"
    >
      {{ $n(bookedOption(optionId).cost, "currency") }}
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    optionId: {
      type: String,
      required: true,
    },
    showPrice: {
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters({
      bookingDetails: "bookingDetails",
      calculationResults: "calculationResults",
    }),
  },
  methods: {
    bookedOption(optionId) {
      var result;
      this.calculationResults.availableBookingOptions.forEach(
        (bookingOption) => {
          bookingOption.selectables.forEach((selectable) => {
            if (selectable.bookingOptionId == optionId && !selectable.isMandatory) {
              result = { name: selectable.name, cost: selectable.cost };
            }
          });
        }
      );

      return result;
    },
  },
};
</script>
