<template>
  <CenterContainer>
    <div class="alternatives-page ofsetted-page">
      <InfoCardForAlternatives
        :headline="$t('bookingAlternativesPage.headline')"
        icon-one-name="car"
        icon-two-name="exclamation-triangle"
        icon-font-size="12px"
        :message="
          offers && offers.length
            ? $t('bookingAlternativesPage.message')
            : $t('bookingAlternativesPage.noAlternatives')
        "
        class="mb-6"
        :on-click-action="navigateToLead"
        :model-name="getModelName"
      />

      <v-row
        v-for="(offer, index) in visibleOffers"
        :key="offer.baseBooking.modelId + index"
        class="booking-alternative"
      >
        <v-col
          class="pt-5 pb-5 booking-information"
        >
          <div class="tooltip-icon-container">
            <TooltipForAlternatives
              v-for="(difference, key) in getOfferDifferences(offer)"
              :key="key"
              :icon-name="difference.icon"
              :icon-for-alternatives="difference.iconForAlternatives"
              :icon-for-available="difference.iconForAvailable"
              :tooltip="difference.tooltip"
              :icon-size="difference.iconSize"
              :icon-padding-bottom="difference.iconPaddingBottom"
            />
          </div>
          <div
            v-ripple
            class="cursor-pointer"
            @click="selectOffer(index)"
          >
            <BookingOfferBox
              :booking-summary="offer.baseBooking"
              :original-end-date="offer.originalEndDate"
              :monthlyPrice="offer.calculationResults.monthlyRentPrice"
              :price="offer.calculationResults.rentPrice"
              :booking-days="offer.calculationResults.bookingDays"
              :included-kms="offer.calculationResults.includedKms"
            />
          </div>
        </v-col>
      </v-row>

      <v-row
        v-if="alternativeOffersMaxSize < offers.length"
      >
        <v-col style="zIndex: 7">
          <v-btn
            block
            @click="alternativeOffersMaxSize = offers.length"
          >
            {{
              $vuetify.breakpoint.width > 400
                ? $t("bookingAlternativesPage.showMore")
                : $t("bookingAlternativesPage.more")
            }}
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <InfoCard
            :headline="$t('bookingAlternativesPage.leadBox.header')"
            icon-name="arrow-alt-circle-right"
            :message="$t('bookingAlternativesPage.leadBox.message')"
            :on-click-action="navigateToLead"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <NavigationButtons :navigate-back-action="navigateBack" />
        </v-col>
      </v-row>
    </div>
  </CenterContainer>
</template>

<script>
import { mapGetters } from "vuex";
import BookingOfferBox from "./BookingOfferBox";
import NavigationButtons from "@/components/ui/NavigationButtons";
import CenterContainer from "@/components/ui/CenterContainer";
import InfoCard from "@/components/ui/InfoCard";
import InfoCardForAlternatives from "@/components/ui/InfoCardForAlternatives";
import TooltipForAlternatives from "@/components/ui/TooltipForAlternatives";
import { router } from "@/router";

export default {
  components: {
    BookingOfferBox,
    NavigationButtons,
    TooltipForAlternatives,
    CenterContainer,
    InfoCard,
    InfoCardForAlternatives
  },
  data: () => ({
    alternativeOffersMaxSize: 3,
  }),
  computed: {
    ...mapGetters({
      offers: "offers",
      sites: "sites",
      selectionFormData: "selectionFormData",
      anyCarWanted: "anyCarWanted"
    }),
    visibleOffers() {
      return this.offers.slice(0, this.alternativeOffersMaxSize);
    },
    getModelName(){
      return this.selectionFormData.modelName;
    }
  },
  async beforeMount() {
    if (!this.offers) {
      router.navigateTo("vehicleSearch");
    }
  },
  methods: {
    navigateBack() {
      router.navigateTo("vehicleSearch");
    },
    getOfferDifferences(offer) {
      const differences = [];
      var alternativeIcon
      if (this.anyCarWanted){
        alternativeIcon = "circle-check"
      } else alternativeIcon = "circle-xmark"

      if (offer.baseBooking.modelName != this.selectionFormData.modelName) {
        differences.push({
          icon: "car",
          tooltip: this.$t("bookingAlternativesPage.offerTooltip.model", {
            wanted: this.selectionFormData.modelName,
            offer: offer.baseBooking.modelName,
          }),
          iconSize: "22px",
          iconVisibility: "visible",
          iconForAlternatives: alternativeIcon,
          iconHeight: 19,
          iconPaddingBottom: "0px"
        });
      } else {
        const differentVariant =
          this.selectionFormData.modelVariantId &&
          offer.baseBooking.modelVariantId != this.selectionFormData.modelVariantId;
        const differentEquipment =
          this.selectionFormData.equipmentLineId &&
          offer.baseBooking.equipmentLineId != this.selectionFormData.equipmentLineId;

        let tooltip;
        if (differentVariant && differentEquipment) {
          tooltip = this.$t(
            "bookingAlternativesPage.offerTooltip.variantAndEquipment",
            {
              wantedVariant: this.selectionFormData.modelVariantName,
              offerVariant: offer.baseBooking.modelVariantName,
              wantedEquipment: this.selectionFormData.equipmentLineName,
              offerEquipment: offer.baseBooking.equipmentLineName,
            }
          );
        } else if (differentVariant) {
          tooltip = this.$t("bookingAlternativesPage.offerTooltip.variant", {
            wanted: this.selectionFormData.modelVariantName,
            offer: offer.baseBooking.modelVariantName,
          });
        } else if (differentEquipment) {
          tooltip = this.$t(
            "bookingAlternativesPage.offerTooltip.equipmentLine",
            {
              wanted: this.selectionFormData.equipmentLineName,
              offer: offer.baseBooking.equipmentLineName,
            }
          );
        }

        if (tooltip) {
          differences.push({
            order: 1,
            icon: "car",
            iconForAlternatives: "circle-xmark",
            tooltip: tooltip,
            iconSize: "22px",
            iconHeight: 19,
            iconPaddingBottom: "0px"
          });
        } else {
          const availableTooltip = this.$t("bookingAlternativesPage.availableTooltip.model", {
            wanted: this.selectionFormData.modelName
          });
          differences.push({
            order: 1,
            icon: "car",
            iconForAvailable: "circle-check",
            tooltip: availableTooltip,
            iconSize: "22px",
            iconHeight: 19,
            iconPaddingBottom: "0px"
          })
        }
      }

      if (offer.baseBooking.siteId != this.selectionFormData.siteId) {
        const tooltip = this.$t("bookingAlternativesPage.offerTooltip.site", {
          offer: offer.baseBooking.siteName,
          wanted: this.selectionFormData.siteName,
        });
        differences.push({
          order: 3,
          icon: "map-marker-alt",
          iconForAlternatives: "circle-xmark",
          tooltip: tooltip,
          iconSize: "24px",
          iconHeight: 23.92,
          iconPaddingBottom: "0px"
        });
      } else {
        const availableTooltip = this.$t("bookingAlternativesPage.availableTooltip.site", {
          wanted: this.selectionFormData.siteName,
        });
        differences.push({
          order: 3,
          icon: "map-marker-alt",
          iconForAvailable: "circle-check",
          tooltip: availableTooltip,
          iconSize: "24px",
          iconHeight: 23.92,
          iconPaddingBottom: "0px"
        });
      }

      if (
        offer.baseBooking.startDate != this.selectionFormData.startDate ||
        (offer.originalEndDate ? offer.originalEndDate : offer.baseBooking.endDate) != this.selectionFormData.endDate
      ) {
        const tooltip = this.$t("bookingAlternativesPage.offerTooltip.date", {
          wanted:
            this.$d(new Date(this.selectionFormData.startDate), "short") +
            " - " +
            this.$d(new Date(this.selectionFormData.endDate), "short"),
        });
        differences.push({
          order: 2,
          icon: "calendar-alt",
          iconForAlternatives: "circle-xmark",
          tooltip: tooltip,
          iconSize: "22px",
          iconHeight: 21.99,
          iconPaddingBottom: "0px"
        });
      } else {
        const availableTooltip = this.$t("bookingAlternativesPage.availableTooltip.date", {
          wanted:
            this.$d(new Date(this.selectionFormData.startDate), "short") +
            " - " +
            this.$d(new Date(this.selectionFormData.endDate), "short"),
        });
        differences.push({
          order: 2,
          icon: "calendar-alt",
          iconForAvailable: "circle-check",
          tooltip: availableTooltip,
          iconSize: "22px",
          iconHeight: 21.99,
          iconPaddingBottom: "0px"
        });
      }

      if (offer.originalEndDate && offer.originalEndDate != offer.baseBooking.endDate) {
        const tooltip = this.$t("bookingSummaryBox.depositBoxInfo");
        differences.push({
          order: 4,
          icon: "house-lock",
          iconForAlternatives: "",
          tooltip: tooltip,
          iconSize: "20px",
          iconHeight: 19.99,
          iconPaddingBottom: "0px"
        });
      }

      let orderedDifferences = differences.sort((a, b) => parseFloat(a.order) - parseFloat(b.order));

      orderedDifferences.map(it => {
        if (it.iconHeight > 21) {
          it.iconPaddingBottom = "0px"
        } else {
          it.iconPaddingBottom = "0px"
        }
      })

      return orderedDifferences
    },
    navigateToLead() {
      router.navigateTo("leadCustomNote");
    },
    selectOffer(offerIndex) {
      router.navigateTo("details", { id: offerIndex, modelVariantId: this.offers[offerIndex].baseBooking.modelVariantId });
    },
  },
};
</script>

<style src="@/styles/pages/AlternativesPage.scss" lang="scss"></style>
