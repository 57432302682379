<template>
  <v-text-field
    ref="birthday"
    outlined
    :label="$t('contactForm.birthday')"
    :placeholder="$t('contactForm.dateFormat')"
    :name="name"
    :value="value"
    :rules="rules"
    :error-messages="errorMessage"
    required
    hide-details="auto"
    @input="$emit('input', $event)"
  >
    <template #message="{ message }">
      <span>{{ message }}<br>
        <span
          v-if="missingBookingOptionId"
          class="error--text"
          @click="addMissingBookingOption()"
        >
          <span class="missingBookingOptionLink">{{
            $t("general.validation.addMissingBookingOption")
          }}</span>
          (+{{ $n(missingBookingOptionPrice, "currency") }})
        </span>
      </span>
    </template>
  </v-text-field>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import axios from "axios";
import { router } from "@/router";

export default {
  props: {
    value: { type: String },
    rules: { type: Array },
    errorMessage: { type: String },
    missingBookingOptionId: { type: String },
    name: { type: String },
  },
  computed: {
    ...mapGetters({
      availableBookingOptions: "availableBookingOptions",
      bookingDetails: "bookingDetails",
      baseBooking: "baseBooking",
      lastGapSeen: "lastGapSeen",
    }),
    missingBookingOptionPrice() {
      var price;

      this.availableBookingOptions.forEach((x) => {
        x.selectables.forEach((y) => {
          if (y.bookingOptionId === this.missingBookingOptionId) {
            price = y.cost;
          }
        });
      });

      return price;
    },
  },

  methods: {
    ...mapMutations({
      UPDATE_ERROR: "UPDATE_ERROR",
    }),
    ...mapActions({
      ADD_BOOKING_OPTION: "ADD_BOOKING_OPTION",
      UPDATE_CALCULATION_RESULTS: "UPDATE_CALCULATION_RESULTS",
    }),
    async addMissingBookingOption() {
      this.ADD_BOOKING_OPTION(this.missingBookingOptionId);
      try {
        var bookingData = {
          booking: this.baseBooking,
          bookingDetails: {
            bookingOptionIds: this.bookingDetails.bookingOptionIds,
            handoverTimeId: this.bookingDetails.handoverTimeId,
            returnTimeId: this.bookingDetails.returnTimeId,
          },
          discount: this.lastGapSeen.modelId != null
        };
        this.$emit("update:loading");
        const res = await axios.post(`/booking/calcPrice`, bookingData);
        this.UPDATE_CALCULATION_RESULTS(res.data);
        if (res.data.alerts?.length && this.loopAlerts(res.data.alerts) || !res.data.isBookable) {
          router.navigateTo("details");
        } else {
          this.$refs.birthday.validate();
        }
      } catch (err) {
        if (!axios.isCancel(err)) {
          this.UPDATE_ERROR({ error: err });
          router.navigateTo("error");
        }
      }
    },
    // Loops through the alerts and returns true if it found an alert with anything else than info as its type
    loopAlerts(alerts){
      alerts?.forEach((a) => {
        if (a.type != "info"){
          return true
        }
      })
    },
  },
};
</script>