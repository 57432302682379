<template>
  <CenterContainer>
    <BookingSummaryBox
      :booking-summary="baseBooking"
      :price="calculationResults.totalPrice"
      :booking-days="calculationResults.bookingDays"
      :included-kms="calculationResults.includedKms"
      :monthlyPrice="calculationResults.totalMonthlyPrice"
      :show-booking-options="true"
    />
    <v-row>
      <v-col
        class="pb-5"
      >
        <DriversInput
          class="mb-5"
          :number-of-drivers="calculationResults.numberOfAdditionalDrivers"
          @update:validation="isValid = $event"
          @update:loading="loading = $event"
        />

        <NavigationButtons
          :navigate-back-action="navigateBack"
          :navigate-forward-action="navigateForward"
          :forward-enabled="isValid && !loading"
          :forward-text-key="
            loading ? 'contactForm.button.loading' : 'general.button.forward'
          "
        />
      </v-col>
    </v-row>
  </CenterContainer>
</template>

<script>
import BookingSummaryBox from "@/components/ui/BookingSummaryBox";
import NavigationButtons from "@/components/ui/NavigationButtons";
import CenterContainer from "@/components/ui/CenterContainer";
import DriversInput from "@/components/ui/Contact/DriversInput";
import { mapGetters, mapMutations } from "vuex";
import { router } from "@/router";

export default {
  components: {
    BookingSummaryBox,
    NavigationButtons,
    CenterContainer,
    DriversInput,
  },

  data: () => ({
    isValid: false,
    loading: false,
  }),
  computed: {
    ...mapGetters({
      baseBooking: "baseBooking",
      bookingDetails: "bookingDetails",
      calculationResults: "calculationResults",
    }),
  },
  async beforeMount() {
    if (!this.baseBooking || !this.bookingDetails) {
      router.navigateTo("vehicleSearch");
    }
  },

  methods: {
    ...mapMutations({
      SET_ADDITIONAL_DRIVERS: "SET_ADDITIONAL_DRIVERS",
    }),
    navigateBack() {
      router.navigateTo("bookingContact");
    },
    navigateForward() {
      router.navigateTo("bookingSubmission");
    },
  },
};
</script>