<template>
<div>
<div class="top-card">
  
  <v-row class="ma-0">
    <v-spacer></v-spacer>
    <v-col class="top-card-info"
      cols="12"
      sm="6"
      
      >
      <div v-if="modelName == '* Beliebiges E-Auto'">
        <div class="information-text float-center">
          {{$t("bookingAlternativesPage.messageAnyCarWanted")}}
        </div>
      </div>
      <div v-else>
        <font-awesome-icon 
        :icon="iconTwoName"
        class="centered info-icon"
        />
        <div class="information-text float-center">
          {{$t("bookingAlternativesPage.headline")}}
        </div>
      </div>
    </v-col>
    <v-col class="top-card-info"  v-if="modelName != '* Beliebiges E-Auto'"
    cols="12"
    sm="6"
    >
    <v-btn 
      x-small
      :class="{'float-center': $vuetify.breakpoint.xs}"
      class="button"
    
      append-icon="car"
      @click="onClickAction()"
    >
      <span>{{$t("bookingAlternativesPage.leadButton.message")}}</span>
      <font-awesome-icon 
        :icon="arrowRight"
        class="centered arrow-icon pb-2 pl-3"
        size="2x"
        />
    </v-btn>
    </v-col>
    <v-spacer></v-spacer>
  </v-row>
    
  <div class="bottom-card">
    <vrow>
      <v-col class="card-information">
        <div ></div>
            <div  v-if="modelName == '* Beliebiges E-Auto'" class="centered">
              {{$t("bookingAlternativesPage.firstMessageAnyCarWanted")}}
            </div>
            <div  v-else class="centered">
              {{$t("bookingAlternativesPage.firstMessage")}}
            </div>
            <div>
              
              <font-awesome-icon 
              :icon="iconOneName"
              class="centered icon"
              />
              
            </div>
            <div v-if="modelName == '* Beliebiges E-Auto'" class="centered title">
              {{$t("bookingAlternativesPage.titleAnyCarWanted")}}
            </div>
            <div v-else class="centered">
              <span class="centered title">{{$t("bookingAlternativesPage.message")}}</span>
              <font-awesome-icon 
              :icon="arrowDown"
              class="arrow-icon pl-4 "
              size="2x"
              />
            </div>
            
      </v-col>
    </vrow>
  </div>
</div>

<!-- 
  <v-row class="info-card">
    <v-col
      class="bb-5"
    >
      <v-card
        class="mx-auto"
        v-on="onClickAction ? { click: onClickAction } : {}"
      >
        <v-card-subtitle class="infoCardHeadline">
          {{ headline }}
        </v-card-subtitle>
        <v-card-subtitle class="infoCardSubtitle">
          {{ message }}
        </v-card-subtitle> 
        <font-awesome-icon 
          :icon="iconOneName" 
          class="card-icon iconInfoCardSubtitle" 
          :style="{color: iconColor, fontSize: iconFontSize}"
        />

        <font-awesome-icon 
          :icon="iconTwoName" 
          class="card-icon" 
          size="2x" 
          :style="{color: iconColor}"
        />
      </v-card>
    </v-col>
  </v-row> -->
</div>
</template>

<script>



export default {
  data: () => ({
    arrowRight: "fa-circle-arrow-right",
    arrowDown: "fa-circle-arrow-down",
  }),
  props: {
    iconOneName: {
      type: String,
    },
    iconTwoName: {
      type: String,
    },
     iconFontSize: {
      type: String
    },
    iconColor: {
      type: String,
    },
    headline: {
      type: String,
    },
    message: {
      type: String,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    onClickAction: {
      type: Function,
      default: null,
    },
    modelName: {
      type: String, 
    }
  },
};

</script>

<style scoped>

.button{
  color: black;
  font-style: bold;
}

.top-card{
  width: 100%;
  height: fit-content;
  background-color: #32a18f;
  border-radius: 10px 10px 10px 10px;
  margin-bottom: 20px;
}

.bottom-card{
  width: 100%;
  font-size: small;
  border: 5px solid;
  border-color: #d1e8e4;
  color: black;
  border-radius: 10px;
  border-width: 3px;
  background-color: white;
  border-style: "solid"
}
.centered{
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  color:black
  
}
.icon{
  padding-top: 10px;
  padding-bottom:5px;
  color: #32a18f;
  text-align: center;
  display: block;
  font-size: large;
}
.info-icon{
  float: left;
  color: white;
  margin: 6px 2px 8px 5px;
}
.title{
  color: #32a18f;
  font-size: xx-large;
  font-style: bold;
}

.top-card-info{
  padding-top: 0px;
  padding-bottom: 0px;
  margin: 0px;
  text-align: center;
}
.information-text{
  padding-top: 5px;
  padding-bottom: 0px;
  color: white;
  font-size: 14px;
  font-style: bold;
  text-align: center;
  float: center;
}

.card-information{
  padding-top: 3px;
  padding-bottom: 3px;
}
.button{
  margin-left: auto;
  margin-right: auto;
  margin: 5px 2px 4px 0px;
}

.float-right{
  float: right !important;
  font-size: larger !important;

}
.arrow-icon{
  padding-top: 8px;
  font-size: 14px;
  color: gray
}

</style>
