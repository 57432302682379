var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CenterContainer',[(_vm.autoSearch)?_c('v-progress-circular',{staticStyle:{"margin":"0 auto","display":"block","margin-top":"20%"},attrs:{"size":"64","color":"#a9a9a9","indeterminate":""}}):_vm._e(),_c('div',{staticClass:"ofsetted-page vehicle-search-page",style:({display: _vm.autoSearch ? 'none' : 'block'})},[_c('v-row',[_c('v-col',[_c('div',{staticClass:"tooltip-icon-container"},[_c('TooltipIcon',{attrs:{"icon-name":"car","tooltip":_vm.$t('vehicleSearchPage.tooltip.chooseModel'),"icon-size":"24px"}})],1),_c('v-select',{attrs:{"items":_vm.dropdownModels,"full-width":true,"loading":_vm.masterDataLoading,"disabled":_vm.masterDataLoading,"solo":"","label":_vm.masterDataLoading
              ? _vm.$t('vehicleSearchPage.dropdown.modelsLoading')
              : _vm.$t('vehicleSearchPage.dropdown.chooseModel')},model:{value:(_vm.SelectCarForm.modelId),callback:function ($$v) {_vm.$set(_vm.SelectCarForm, "modelId", $$v)},expression:"SelectCarForm.modelId"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"calendarmain"},[_c('div',{staticClass:"tooltip-icon-container"},[_c('TooltipIcon',{attrs:{"icon-name":"calendar-alt","tooltip":_vm.$t('vehicleSearchPage.tooltip.chooseDate'),"icon-size":"24px"}})],1),_c('BookingCalendar',{attrs:{"range-data":{
            start: _vm.SelectCarForm.startDate,
            end: _vm.SelectCarForm.endDate,
          }},on:{"setStartDate":function($event){_vm.SelectCarForm.startDate = $event},"setEndDate":function($event){_vm.SelectCarForm.endDate = $event}}})],1)],1),_c('v-row',[_c('v-col',[_c('div',{staticClass:"tooltip-icon-container"},[_c('TooltipIcon',{attrs:{"icon-name":"map-marker-alt","tooltip":_vm.$t('vehicleSearchPage.tooltip.chooseCity'),"icon-size":"24px"}})],1),_c('v-select',{attrs:{"items":_vm.dropdownSites,"full-width":true,"loading":_vm.masterDataLoading,"disabled":_vm.masterDataLoading,"solo":"","label":_vm.masterDataLoading
              ? _vm.$t('vehicleSearchPage.dropdown.sitesLoading')
              : _vm.$t('vehicleSearchPage.dropdown.chooseCity')},model:{value:(_vm.SelectCarForm.siteId),callback:function ($$v) {_vm.$set(_vm.SelectCarForm, "siteId", $$v)},expression:"SelectCarForm.siteId"}})],1)],1),(
        _vm.selectedModel.modelVariants &&
          _vm.selectedModel.modelVariants.filter((x) => x.isSelectable).length > 0
      )?_c('v-row',[_c('v-col',{staticClass:"pt-4 pb-4"},[_c('div',{staticClass:"tooltip-icon-container"},[_c('TooltipIcon',{attrs:{"icon-name":"car-battery","tooltip":_vm.$t('vehicleSearchPage.tooltip.chooseVariant'),"icon-size":"24px"}})],1),_c('v-row',{attrs:{"dense":""}},_vm._l((_vm.variants.filter(
              (x) => x.isSelectable
            )),function(variant){return _c('v-col',{key:variant.modelVariantId,attrs:{"md":_vm.decideMdGivenName(variant.name.length),"sm":_vm.decideSmGivenName(variant.name.length),"cols":_vm.decideColsGivenName(variant.name.length)}},[_c('button',{staticClass:"toggle-button",class:{active : _vm.SelectCarForm.modelVariantId == variant.modelVariantId },attrs:{"required":""},on:{"click":function($event){return _vm.setActive(variant.modelVariantId)}}},[_vm._v(" "+_vm._s(variant.name)+" ")])])}),1)],1)],1):_vm._e(),_c('v-row',[_c('v-col',[_c('Alerts',{attrs:{"alerts":_vm.alerts}})],1)],1),_c('v-row',[_c('v-col',[(_vm.calculationResults.bookingDays > 0 || _vm.loading)?_c('PriceBox',{attrs:{"total-price":this.isMonthlyPrice() ? _vm.calculationResults.totalMonthlyPrice : _vm.calculationResults.totalPrice,"total-days":this.isMonthlyPrice() ? _vm.month : _vm.calculationResults.bookingDays,"is-monthly-price":this.isMonthlyPrice(),"is-from-price":true,"loading":_vm.loading}}):_vm._e()],1)],1),_c('v-row',[_c('v-col',[_c('NavigationButtons',{attrs:{"navigate-forward-action":_vm.navigateForward,"error-action":_vm.onForwardError,"forward-enabled":_vm.forwardButtonEnabled,"requires-captcha":_vm.isBookable,"forward-text-key":_vm.forwardButtonTextKey,"auto-proceed":_vm.proceed}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }