<template>
  <div>
  <v-card class="default-option" :class="isDefault() ? 'selected' : ''" :elevation ="2" :ripple=false @click="onClick(-1)">
    <div :class="isDefault() ? 'selected-radio-button-default' : 'radio-button'"></div>
    <span class="single-option-title">
      {{ standardOption.name }}
    </span>
    <div class="single-option">
      <div class="price-information" >
        <span class="cost">{{ $n(standardOption.cost, 'currency') }}</span>
      </div>
      <p class="clue single-option-underlined-text" @click="toggleDescription()">{{ details }}</p>
    </div>
    <div v-if="detailsVisible" class="custom-checkbox-tooltip">
      {{ tooltipDescription }}
    </div>
  </v-card>
  <div class="bigger-rectangle">
    <v-card :elevation ="2" :ripple=false v-for="(rect, index) in rectangles" :key="index" :class="['rectangle', {open:rect.open, selected:rect.selected, closed: rect.closed }]" @click="onClick(index)">
        <div :class="isSelected(index) ? 'selected-radio-button' : 'radio-button'"></div>
        <span class="option-title">
          <span>{{ optionTexts[index].name.split(" ")[0] }} </span><br v-if="breakNeeded(index)">
          <span v-for=" (item) in getOptionTitle(optionTexts[index].name)" :key="item">{{ item }} </span>
        </span>
        <div class="icon" v-if="isExpanded(index)">
          <font-awesome-icon
            :icon="getIconName(optionTexts[index].name)"
            style="font-size: 150%;"
            size="2x"
          />
        </div>
        <div class="options" :class="!isExpanded(index) ? '' : 'expanded-options'">
          <div class="price-information" >
            <span v-if="isRecurring(index)" class="clue"  >einmalig</span> <br v-if="!isExpanded(index)">
            <span v-if="isMonthlyPrice" class="cost">{{ formatCurrency(optionTexts[index].monthlyCost) }}</span>
            <span v-else class="cost">{{ formatCurrency(optionTexts[index].cost) }}</span>
            <br>
          </div>
          
          <p class="clue-expanded" :class="isExpanded(index) ? '' : 'underlined-text'">{{ getDescription(index, optionTexts[index]) }}</p>
        </div>
    </v-card>
  </div>
</div>
</template>
<script>


import { mapGetters } from "vuex";


export default {
  data() {
      return {
        oldId: null, //used to store the users last choice
        state: 1,
        
        isTextExpanded: false,
        timeout: null,
        selectedId: null, //used to restore the already selected option when reentering the page
        showTooltip: false,
        tooltipStyle: {},

        details: "mehr Details",
        detailsVisible: false,
        detailsTimeout: null,
      };
    },
  components: {

  },
  watch: {
    numRectangles: {
      immediate: true,
      handler(newVal) {
        this.initializeRectangles(newVal);
      },
    },
  },


  props: {
    initialSelection: { type: Object },
    optionTexts: { type: Array},
    rentPrice: {
      type: Number,
      required: false,
    },
    standardOption:{
    },
    isMonthlyPrice: {
      type: Boolean,
      default: false,
    }
  },

  async beforeMount(){
    this.selectedId = this.initialSelection?.bookingOptionId;
    this.oldId = this.selectedId; //important for resetting the option
    this.initializeRectangles(this.optionTexts.length);
  },

  computed: {
    ...mapGetters({
      bookingDetails: "bookingDetails",
    }),
    tooltipDescription() {
      return this.standardOption.description; //In this component tool tips are just used for the first element
    },
    pricePerDay() {
      return this.totalDays !== 0
        ? (
            (this.totalPrice ? this.totalPrice : this.rentPrice) /
            this.totalDays
          ).toFixed(2)
        : 0;
    },
  },
  methods: {
    // get the icon name according to which keyword comes in the title. If none show up then a car is automatically given as the icon.
    getIconName(title){
      var iconName = "car";
      if(title.indexOf("meter") != -1){
        iconName = "road";
      }
      if(title.indexOf("sitz") != -1){
        iconName = "child-reaching";
      }
      if(title.indexOf("fahrer") != -1){
        iconName = "user-plus";
      }
      if(title.indexOf("beteiligung") != -1){
        iconName = "shield-halved";
      }
 
      return iconName
    },

    // sets the options (rectangles) to their starting position or to their last position. This depends on if the option was booked already
    initializeRectangles(num) {
      this.rectangles = Array.from({ length: num }, () => ({
        open: false,
        selected: false,
        closed: false,
      }));
      for (let step = 0; step < this.rectangles.length; step++) { 
        // restores the selection if the booking option was previously selected 
        if(this.optionTexts[step]?.bookingOptionId == this.selectedId){
          this.rectangles[step].selected = true;
          this.state = 3;
        }
      }
    },
    //determines if the default option should be selected
    isDefault(){
      return this.state == 1 ? true : false;
    },

    formatCurrency(bookingOptionCost) {
      return this.$n(bookingOptionCost, "currency")
    },

    onClick(index) {
      if(this.optionTexts[index]?.bookingOptionId){ // null safety check
        if(this.optionTexts[index]?.bookingOptionId != this.oldId){
           // transmit to parent. If option was deselected transmit null
          this.$emit('selected-option', true, this.oldId);
          this.$emit('selected-option', false, this.optionTexts[index]?.bookingOptionId);
          this.oldId = this.optionTexts[index]?.bookingOptionId;
        }else{
          this.oldId = this.optionTexts[index].bookingOptionId;
        }
      }
        
      this.expandAction(index, false);
      this.toggleOptions(index, false);

      if (this.rectangles[index]?.open){
        this.timeout = setTimeout(() => this.expandAction(index, true), 5000);
      }else{
        clearTimeout(this.timeout);
        this.timeout = null;
      }

      if(index < 0){ //if default option was clicked
        this.$emit('selected-option', true, this.oldId);
        this.oldId = null;
        this.state = 1;
        this.rectangles.forEach((item)=> {
          item.closed = false;
          item.open = false;
          item.selected = false;
          
        });
        clearTimeout(this.timeout);
        this.timeout = null;
        
      }
    },
    expandAction(index, timeTriggered){
      switch (this.state) {
        case 1:
          if(!timeTriggered){// triggered through a click
            this.rectangles.forEach((rect, i) => {
            //the option that was clicked
              if (i === index) {
                rect.open = true;
              }
              else{
                rect.closed = true;
              }
            });
          }
          this.state = 2;
          break;
        case 2 :
            if(!timeTriggered){// triggered through a click
              this.rectangles.forEach((rect, i) => {
              if (i == index) {//the option that was clicked
                rect.open = false;
              }
              else{
                rect.closed = false;
              }
              });
              this.state = 3;
            }else{ // triggered through timer
              this.rectangles.forEach((rect, i) => {
              if (i == index) {
                rect.open = false;
              }
              else{
                rect.closed = false;
              }
              });
              this.state = 3;
            }
            break;
          case 3:
            if(!timeTriggered){// triggered through a click
              this.rectangles.forEach((rect, i) => {
              //the option that was clicked
              if (i == index) {
                if(rect.selected){
                  rect.open = true;
                  this.state = 2;
                }else{
                  rect.open = true;
                  this.rectangles.forEach((item, j) => {
                    if(j != i){
                      item.closed = true;
                    }
                  });
                  this.state = 2;
                }
                
              }
              else{
                rect.closed = true;
              }
            });
            }
            break;
            default:
          }
    },
    //used if any option (rectangle) was selected
    toggleOptions(index, timeTriggered){
      this.rectangles.forEach((rect, i) => {
        if (i === index) {
          if(!timeTriggered){
            rect.selected = true;
          }
        }else if(index < 0){
          rect.closed = false;
        }
        else {
          rect.selected = false;
        }

      });
    },

    isExpanded(index){
      return this.rectangles[index].open;
    },

    isSelected(index){
      return this.rectangles[index].selected;
    },

    getDescription(index, option) {
      return this.rectangles[index].open ? option.description : "mehr Details";
    },

    toggleDescription(){
      clearTimeout(this.detailsTimeout);
      this.detailsVisible = !this.detailsVisible;
      this.detailsTimeout = setTimeout(() => this.detailsVisible = false, 2000);
    },

    getOptionTitle(name){
      var title = name.split(" ");
      title.shift();
      return  title;
    },
    // determines if break is needed. Just for visuals
    breakNeeded(index){
      var expanded = this.isExpanded(index);
   
      var shortText = this.getOptionTitle(this.optionTexts[index].name).length < 4;
      if(expanded){
        if(!(shortText || window.width < 600)){
          return true;
        }else{
          return false;
        }
      }else{
        return false;
      }
    },
    // if option if only paid once during the whole timeframe of the car rent, it returns false
    isRecurring(index){
      if(this.optionTexts[index].cost == this.optionTexts[index].monthlyCost && this.isMonthlyPrice){
        return true;
      }
      return false;
    }
  }
};
</script>
<style scoped>

/* used to dynamically change the width of the squares */
:root {
  --option-width: 30%;
}

 .default-option{
  height: fit-content;
  display: block;
  margin: 0px 5px 5px 5px;
  padding-top:0px;
  border-radius: 10px;
  border-style: solid;
  border-width: 2px;
  border-color: #ffffff;
 }
 .default-option.selected{
  /* color:#ffffff; */
  border-color: #00806B;
 }
  .rectangle{
      width:50%; 
      height: 70px;
      margin-left: 2px;
      margin-right: 2px;
      display: inline-block;
      overflow: hidden;
      background-color: #ffffff;
    	transition: color 0.5s, width 1s ease, opacity 2s ease, background-color 0.5s ease, padding 1s ease;
      justify-content: center;
      pointer-events:auto;
      border-radius: 10px;
  }
  .rectangle.closed{
    overflow: hidden; 
    width: 0%;

    opacity: 0px;
    pointer-events:none;
    margin-left: 0px;
    margin-right: 0px;
    color:#ffffff
  }
  .rectangle.open{
    overflow: hidden; 
    width: 99%;
    opacity: 1px;
    margin: 0px;
    padding-left: 10px;
  }
  .rectangle.selected{
    color:#ffffff;
    background-color: #00806B;
  }
  .bigger-rectangle{
    display: flex;
    height: 75px;
    overflow: hidden;
    /* margin-left: auto;
    margin-right: auto; */
    padding-left: 2px;
    position: relative;
  }
  .text{
    padding: 10px;
    color: #ffffff;
    overflow: hidden;
    height: 100px;
    transition: width 3s ease, opacity 2s ease; 
    pointer-events:auto;
    white-space: nowrap; 
    text-overflow: ellipsis; 
  }
  .text.closed{
    width: 0%;
    pointer-events:none;
    opacity: 0;
  }
  .text.open{
    color: #ffffff !important;
    overflow: hidden; 
    width: 30%;
    opacity: 1px;
    pointer-events:auto;
  }
  .clue{
    font-size: x-small;
    margin-right: 2px;
    width: fit-content;
    margin-bottom: 0px;
    
  }
  .clue-expanded{
    font-size: x-small;
    margin-right: 2px;
    width:fit-content;
    margin-bottom: 0px;
  }
  .options{
    margin-right: 10px;
    margin-left: 5px;
    margin-bottom: 5px;
    position: absolute;
    bottom:0px;
    right:0px;
    left:0px;
    display: inline-block;
    line-height: 14px;
    
  }
  .expanded-options{
    margin-left: 15px;
  }
  .single-option{
    margin-right: 5px;
    margin-left: 5px;
    width: auto;
    height:auto;
  }
  .single-option-underlined-text{
    text-decoration: underline;
    position: absolute;
    bottom: 5px;
    right: 10px;
  }
  .cost{
    font-size: small;
    padding-top: 10px;
    height: fit-content;
    display: inline-block;
  }
  .price-information{
    line-height: 1px;
    display: inline-block;
  }
  .icon{
    display: inline-block;
    color: #ffffff;
    position: absolute;
    right:15%;
    top:30%;
  }
  .single-option-title{
    text-overflow: ellipsis;
    white-space: nowrap; 
    width: auto; 
    overflow: hidden;
    display: block;
    margin-left: 5px;
    margin-top: 5px;
    margin-right: 5px;
  }
  .option-title{
    text-overflow: ellipsis;
    white-space: nowrap; 
  
    overflow: hidden;
    display: block;
    margin-left: 5px;
    margin-top: 5px;
    margin-right: 10px;
    padding-right:5px;
    
  }
  .underlined-text{
    text-decoration: underline;
    position: absolute;
    bottom: 0px;
    right: 0px;
    line-height: 10px;
  }
  .selected-radio-button{
    border-radius: 10px !important;
    box-shadow: 0px 0px 0px 2px white;
    width: 15px;
    height:15px;
    margin-right: 10px;
    margin-top: 10px;
    float: right;
    border-style: solid;
    border-width: 2px;
    background-color: #ffffff;
    border-color: #00806B;
    transition: background-color 1s ease;
    transition: background-color 3s ease, border 0.5s ease;
  }
  .selected-radio-button-default{
    border-radius: 10px !important;
    box-shadow: 0px 0px 0px 2px #00806B;
    width: 15px;
    height:15px;
    margin-right: 10px;
    margin-top: 10px;
    float: right;
    border-style: solid;
    border-width: 2px;
    background-color: #00806B;
    border-color: #ffffff;
    transition: background-color 0.5s ease, border 0.5s ease;
  }
  .radio-button{
    border-radius: 10px !important;
    box-shadow: 0px 0px 0px 2px gray;
    width: 15px;
    height: 15px;
    margin-right: 10px;
    margin-top: 10px;
    float: right;
    border:white;
    border-style: solid;
    border-width: 2px;
    background-color: #ffffff;
    transition: background-color 3s ease, border 0.5s ease;
  }
  .tips{
    background-color: #dedede;
    padding: 5px 10px 5px 10px;
    border-radius: 10px;
  }
  .custom-checkbox-tooltip{
    border-radius: 5px 5px 5px 5px !important;
  }

  @media only screen and (max-width: 600px) {
  .single-option-title {
    font-size: 13px;
  }
  .option-title {
    font-size: 13px;
  }
  .cost {
    font-size: 12px;
  }
  .selectables-details {
    font-size: 10px;
  }
  .span.checkbox-option-text {
    font-size: 14px;
  }
  .checkbox-option-details{
    font-size: 12px;
  }
  .selectable-booking-options-price-details {
    display: block;
  }
}

@media only screen and (max-width: 1400px) and (min-width: 600px)  {
  .custom-checkbox-details {
    padding: 5px !important;
  }
  .option-title {
    line-height: 15px;
  }

}

@media only screen and (max-width: 600px) and (min-width: 400px)  {
  .checkbox-selectables-details {
    font-size: 9px !important;
  }
  .cost {
    font-size: 10px !important;
  }
  .selectables-details {
    font-size: 9px !important;
  }
  .custom-checkbox-details {
    padding: 5px !important;
  }
  .single-option-title {
    font-size: 12px;
  }
  .option-title {
    font-size: 12px;
    line-height: 15px;

  }
  #checkbox-option-text-id{
    font-size: 12px;
  }
  .underlined-text{
    width: 30px;
    font-size: 9px;
    text-align: right;
  }
  .clue-expanded{
    font-size: 8px;
    line-height: 10px;
    width: 100%
  }
}

@media only screen and (max-width: 400px) {
  .checkbox-selectables-details {
    font-size: 8px !important;
  }
  .custom-checkbox-details {
    padding: 4px !important;
  }
  .single-option-title {
    font-size: 10px;
    margin-top: 10px;
  }
  .option-title {
    font-size: 10px;
    margin-top: 10px;
    padding-right: 10px;
    line-height: 10px;
  }
  .cost {
    font-size: 8px !important;
  }
  .selectables-details {
    font-size: 8px !important;
  }
  #checkbox-option-text-id{
    font-size: 10px;
  }
  .checkbox-option-text {
    font-size: 10px;
  }
  .checkbox-option-details{
    font-size: 8px;
  }
  .selectable-booking-options-price-details {
    display: block;
  }
  .clue{
    font-size: 8px;
  }
  .clue-expanded{
    font-size: 8px;
    line-height: 10px;
    width: 100%
  }
  .underlined-text{
    width: 30px;
    font-size: 6px;
    text-align: right;
  }
  .options{
    margin-right: 0px;
    margin-bottom: 5px;
    margin-right: 5px;
  }
}
</style>

<style src="..\..\styles\components.scss" lang="scss"></style>
