<template>
  <CenterContainer>
    <BookingSummaryBox
      :booking-summary="baseBooking"
      :price="calculationResults.totalPrice"
      :monthlyPrice="calculationResults.totalMonthlyPrice"
      :booking-days="calculationResults.bookingDays"
      :included-kms="calculationResults.includedKms"
      :price-per-additional-km="calculationResults.pricePerAdditionalKm"
      :deductible="calculationResults.deductible"
      :show-booking-options="true"
      class="mb-8"
    />

    <v-row v-if="this.acceptedTermsAndConditionsOnly">
      <v-col class="pb-5">
        <v-card class="mx-auto">
          <v-card-text>
            <p class="box-headline">
              {{ $t("thankYouPage.acceptedTermsAndConditions.line1") }}
            </p>
            <p class="pb-2">
              {{$t("thankYouPage.acceptedTermsAndConditions.line2")}}
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col class="pb-5">
        <v-card class="mx-auto">
          <v-card-text>
            <p class="box-headline">
              {{ $t("thankYouPage.line1") }}
            </p>
            <p class="pb-2">
              {{
                orderName
                  ? $t("thankYouPage.line2WithOrderName", [orderName])
                  : $t("thankYouPage.line2")
              }}
            </p>
            <p class="pb-2">
              {{ $t("thankYouPage.line3") }}
            </p>
            <p class="pb-2">
              {{ $t("thankYouPage.line4") }}
            </p>

            <p class="pb-0 mb-0 hint">
              {{ $t("thankYouPage.uwgHint") }}
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pb-5">
        <NavigationButtons
          forward-text-key="thankYouPage.button.back"
          :navigate-forward-action="navigateToVehicleSearch"
        />
      </v-col>
    </v-row>
  </CenterContainer>
</template>

<script>
import BookingSummaryBox from "@/components/ui/BookingSummaryBox";
import CenterContainer from "@/components/ui/CenterContainer";
import NavigationButtons from "@/components/ui/NavigationButtons";
import { mapGetters, mapMutations } from "vuex";
import { router } from "@/router";

export default {
  components: {
    BookingSummaryBox,
    CenterContainer,
    NavigationButtons,
  },
  beforeRouteLeave(to, from, next) {
    this.RESET_AVAILABILITY_STATE();
    this.RESET_BOOKING_STATE();
    this.RESET_CONTACT_STATE();
    this.RESET_CALCULATION_RESULT_STATE();
    next();
  },
  data: () => ({
    orderName: "",
    acceptedTermsAndConditionsOnly: false,
  }),

  async beforeMount() {
    if (!this.submissionSuccessfull) {
      router.navigateTo("vehicleSearch");
    } else {
      this.orderName = this.$router.currentRoute.params.orderName;
      this.acceptedTermsAndConditionsOnly = this.$router.currentRoute.params.acceptedTermsAndConditionsOnly;
      this.SET_SUBMISSION_SUCCESS(false);
    }
  },
  methods: {
    ...mapMutations({
      RESET_AVAILABILITY_STATE: "RESET_AVAILABILITY_STATE",
      RESET_BOOKING_STATE: "RESET_BOOKING_STATE",
      RESET_CONTACT_STATE: "RESET_CONTACT_STATE",
      RESET_CALCULATION_RESULT_STATE: "RESET_CALCULATION_RESULT_STATE",
      SET_SUBMISSION_SUCCESS: "SET_SUBMISSION_SUCCESS",
    }),
    navigateToVehicleSearch() {
      router.navigateTo("vehicleSearch");
    },
  },

  computed: {
    ...mapGetters({
      baseBooking: "baseBooking",
      contactData: "contactData",
      submissionSuccessfull: "submissionSuccessfull",
      calculationResults: "calculationResults",
    }),
  },
};
</script>
