<template>
  <v-row class="info-card">
    <v-col
      class="bb-5"
    >
      <v-card
        class="mx-auto"
        v-on="onClickAction ? { click: onClickAction } : {}"
      >
        <v-card-title class="font-weight-regular subtitle-1">
          {{ headline }}
        </v-card-title>
        <v-card-subtitle>
          {{ message }}
        </v-card-subtitle>
        <font-awesome-icon
          :icon="iconName"
          class="card-icon"
          size="2x"
        />
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
    },
    headline: {
      type: String,
    },
    message: {
      type: String,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    onClickAction: {
      type: Function,
      default: null,
    },
  },
};
</script>