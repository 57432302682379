<template>
  <div
    class="tooltip-icon"
    :style="{paddingBottom: iconPaddingBottom}"
  >
    <v-tooltip
      left
      color="grey lighten-2"
    >
      <template #activator="{ on, attrs }">
        <v-btn
          icon
          fab
          :x-small="alwaysSmall || $vuetify.breakpoint.width < 600"
        >
          <font-awesome-layers>
            <font-awesome-icon
              :icon="iconName"
              v-bind="attrs"
              :style="{fontSize: iconSize}"
              v-on="on"
            />

            <font-awesome-icon
              v-if="iconForAlternatives"
              :icon="iconForAlternatives"
              v-bind="attrs"
              class="availability-icon-for-alternatives"
              v-on="on"
            />

            <font-awesome-icon
              v-else
              :icon="iconForAvailable"
              v-bind="attrs"
              class="availability-icon-for-alternatives"
              v-on="on"
            />
          </font-awesome-layers>
        </v-btn>
      </template>
      <span
        class="tooltip-text"
        v-html="tooltip"
      />
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    iconName: { type: String },
    iconForAlternatives: { type: String },
    iconForAvailable: { type: String },
    tooltip: { type: String },
    alwaysSmall: { type: Boolean },
    iconSize: {type: String},
    iconPaddingBottom: {type: String}
  },
};
</script>
