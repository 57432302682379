<template>
  <div
    class="tooltip-icon"
    :style="{paddingBottom: iconPaddingBottom}"
  >
    <v-tooltip
      v-if="tooltip"
      left
      color="grey lighten-2"
    >
      <template #activator="{ on, attrs }">
        <v-btn
          icon
          fab
          :x-small="alwaysSmall || $vuetify.breakpoint.width < 600"
        >
          <font-awesome-icon
            :icon="iconName"
            size="2x"
            v-bind="attrs"
            :style="{fontSize: iconSize, zIndex: 2}"
            v-on="on"
          />
        </v-btn>
      </template>
      <span
        class="tooltip-text"
        v-html="tooltip"
      />
    </v-tooltip>
    <font-awesome-icon
      v-else
      :icon="iconName"
      size="2x"
    />
  </div>
</template>

<script>
export default {
  props: {
    iconName: { type: String },
    iconVisibility: { type: String },
    iconForAlternatives: { type: String },
    tooltip: { type: String },
    alwaysSmall: { type: Boolean },
    iconSize: {type: String},
    iconPaddingBottom: {type: String}
  },
};
</script>
