<!-- todo #617: merge with PriceEnquiryPage.vue when updated to vue3 -->
<template>
  <CenterContainer>
    <v-progress-circular
      v-if="autoSearch"
      size="64"
      color="#a9a9a9"
      indeterminate
      style="margin: 0 auto; display: block; margin-top: 20%;"
    />
    <div
      :style="{display: autoSearch ? 'none' : 'block'}"
      class="ofsetted-page vehicle-search-page"
    >
      <!-- Model Selection -->
      <v-row>
        <v-col>
          <div class="tooltip-icon-container">
            <TooltipIcon
              icon-name="car"
              :tooltip="$t('vehicleSearchPage.tooltip.chooseModel')"
              icon-size="24px"
            />
          </div>
          <v-select
            v-model="SelectCarForm.modelId"
            :items="dropdownModels"
            :full-width="true"
            :loading="masterDataLoading"
            :disabled="masterDataLoading"
            solo
            :label="
              masterDataLoading
                ? $t('vehicleSearchPage.dropdown.modelsLoading')
                : $t('vehicleSearchPage.dropdown.chooseModel')
            "
          />
        </v-col>
      </v-row>

      <!-- Date selection -->
      <v-row>
        <v-col class="calendarmain">
          <div class="tooltip-icon-container">
            <TooltipIcon
              icon-name="calendar-alt"
              :tooltip="$t('vehicleSearchPage.tooltip.chooseDate')"
              icon-size="24px"
            />
          </div>
          <BookingCalendar
            :range-data="{
              start: SelectCarForm.startDate,
              end: SelectCarForm.endDate,
            }"
            @setStartDate="SelectCarForm.startDate = $event"
            @setEndDate="SelectCarForm.endDate = $event"
          />
        </v-col>
      </v-row>

      <!-- Location selection -->
      <v-row>
        <v-col>
          <div class="tooltip-icon-container">
            <TooltipIcon
              icon-name="map-marker-alt"
              :tooltip="$t('vehicleSearchPage.tooltip.chooseCity')"
              icon-size="24px"
            />
          </div>
          <v-select
            v-model="SelectCarForm.siteId"
            :items="dropdownSites"
            :full-width="true"
            :loading="masterDataLoading"
            :disabled="masterDataLoading"
            solo
            :label="
              masterDataLoading
                ? $t('vehicleSearchPage.dropdown.sitesLoading')
                : $t('vehicleSearchPage.dropdown.chooseCity')
            "
          />
        </v-col>
      </v-row>

      <!-- Variant Selection -->
      <v-row
        v-if="
          selectedModel.modelVariants &&
            selectedModel.modelVariants.filter((x) => x.isSelectable).length > 0
        "
      >
        <v-col class="pt-4 pb-4">
          <div class="tooltip-icon-container">
            <TooltipIcon
              icon-name="car-battery"
              :tooltip="$t('vehicleSearchPage.tooltip.chooseVariant')"
              icon-size="24px"
            />
          </div>

          <v-row dense>
            <v-col
              v-for="variant in variants.filter(
                (x) => x.isSelectable
              )"
              :key="variant.modelVariantId"
              :md="decideMdGivenName(variant.name.length)"
              :sm="decideSmGivenName(variant.name.length)"
              :cols="decideColsGivenName(variant.name.length)"
            >
              <button
                required
                class="toggle-button"
                :class="{active : SelectCarForm.modelVariantId == variant.modelVariantId }"
                @click=" setActive(variant.modelVariantId)
                "
              >
                {{ variant.name }}
              </button>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <Alerts :alerts="alerts" />
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <PriceBox
            v-if="calculationResults.bookingDays > 0 || loading"
            :total-price="this.isMonthlyPrice() ? calculationResults.totalMonthlyPrice : calculationResults.totalPrice"
            :total-days="this.isMonthlyPrice() ? month : calculationResults.bookingDays"
            :is-monthly-price="this.isMonthlyPrice()"
            :is-from-price="true"
            :loading="loading"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <NavigationButtons
            :navigate-forward-action="navigateForward"
            :error-action="onForwardError"
            :forward-enabled="forwardButtonEnabled"
            :requires-captcha="isBookable"
            :forward-text-key="forwardButtonTextKey"
            :auto-proceed="proceed"
          />
        </v-col>
      </v-row>
    </div>
  </CenterContainer>
</template>
<script>
import {mapGetters, mapActions, mapMutations} from "vuex";
import BookingCalendar from "@/components/ui/Calendar";
import NavigationButtons from "@/components/ui/NavigationButtons";
import CenterContainer from "@/components/ui/CenterContainer";
import PriceBox from "@/components/ui/PriceBox";
import TooltipIcon from "@/components/ui/TooltipIcon";
import Alerts from "@/components/ui/Alerts";
import axios from "axios";
import {setUrlParams} from "@/utils";
import {router} from "@/router";

export default {
  components: {
    BookingCalendar,
    PriceBox,
    NavigationButtons,
    TooltipIcon,
    CenterContainer,
    Alerts,
  },
  data() {
    return {
      autoSearch: false,
      proceed: false,
      isActive: true,
      selectedModel: {},
      variants: [],
      price: 0,
      alerts: [],
      isBookable: false,
      SelectCarForm: {
        modelId: "",
        modelName: "",
        belongsTo: "", 
        siteId: "",
        equipmentLineId: null,
        modelVariantId: null,
        startDate: "",
        endDate: "",
      },
      loading: false,
      month: 28,
    };
  },
  watch: {
    models() {
      this.fillFormFromUrlOrStore(); // when page was refreshed, fill form as soon as models are loaded
    },
    "SelectCarForm.modelId"(selectedModelId) {
      this.models.forEach((m) => {
        if (m.modelId === selectedModelId) {
          this.selectedModel = m;
          this.SelectCarForm.modelName = m.name;
          this.SelectCarForm.belongsTo = m.BelongsTo;
          this.variants = this.selectedModel.modelVariants
          const obj = {
            "isSelectable": true,
            "name": this.$t("vehicleSearchPage.anyVariant")
          }
          this.variants = [obj, ...this.variants]

          if (
              this.SelectCarForm.modelVariantId &&
              !m.modelVariants
                  .map((x) => x.modelVariantId)
                  .includes(this.SelectCarForm.modelVariantId)
          ) {
            this.SelectCarForm.modelVariantId = "";
          }

          if (
              this.SelectCarForm.equipmentLineId &&
              !m.equipmentLines
                  .map((x) => x.equipmentLineId)
                  .includes(this.SelectCarForm.equipmentLineId)
          ) {
            this.SelectCarForm.equipmentLineId = "";
          }
        }
      });
    },
    "SelectCarForm.startDate": "dateRangeChanged",
    "SelectCarForm.endDate": "dateRangeChanged",
    SelectCarForm: {
      immediate: true,
      deep: true,
      handler() {
        this.setUrlParamsFromForm();
        this.calcPrice();
      },
    },
  },
  async beforeMount() {
    if (this.masterDataError) {
      this.UPDATE_ERROR({error: "backend.noMasterData"});
      router.navigateTo("error");
    } else if (!this.masterDataLoading) {
      // do nothing when page was refreshed and models are not loaded yet
      this.fillFormFromUrlOrStore();
    }
    // If query parameter autoSearch is present, hide entire component and display spinner
    if (this.$route.query.autoSearch == "true") {
      this.autoSearch = true;
    }
    await this.RESET_LAST_GAP_SEEN()
  },
  computed: {
    ...mapGetters({
      models: "models",
      sites: "sites",
      isAvailable: "isAvailable",
      selectionFormData: "selectionFormData",
      bookingDetails: "bookingDetails",
      baseBooking: "baseBooking",
      lastGapSeen: "lastGapSeen",
      calculationResults: "calculationResults",
      masterDataLoading: "masterDataLoading",
      masterDataError: "masterDataError",
      anyCarWanted: "anyCarWanted",
    }),
    dropdownModels() {
      var modelNames = new Set();
      const models = this.models.map((x) => {
        if (!modelNames.has(x.name)) {
          modelNames.add(x.name)
          return {text: x.name, value: x.modelId};
        }
      });
      return models.sort((a, b) => (a.text > b.text) ? 1 : -1)
    },
    dropdownSites() {
      return this.sites.map((x) => {
        return {text: x.name, value: x.siteId};
      });
    },
    forwardButtonEnabled() {
      return (
          !this.alerts.find((x) => x.type == "error") &&
          !!this.SelectCarForm.startDate &&
          !!this.SelectCarForm.modelId &&
          !!this.SelectCarForm.siteId &&
          !this.loading
      );
    },
    forwardButtonTextKey() {
      if (!this.SelectCarForm.modelId) {
        return "vehicleSearchPage.button.missingModel";
      }
      if (!this.SelectCarForm.startDate) {
        return "vehicleSearchPage.button.missingDate";
      }
      if (!this.SelectCarForm.siteId) {
        return "vehicleSearchPage.button.missingSite";
      }

      return this.isBookable
          ? "vehicleSearchPage.button.checkAvailability"
          : "vehicleSearchPage.button.toLead";
    },
  },
  methods: {
    setActive(variant) {
      this.SelectCarForm.modelVariantId = this.SelectCarForm.modelVariantId == variant ? '' : variant
      return this.SelectCarForm.modelVariantId
    },
    ...mapActions({
      CHECK_AVAILABILITY: "CHECK_AVAILABILITY",
      SAVE_SELECTION_FORM_DATA: "SAVE_SELECTION_FORM_DATA",
      UPDATE_CALCULATION_RESULTS: "UPDATE_CALCULATION_RESULTS",
    }),
    ...mapMutations({
      UPDATE_ERROR: "UPDATE_ERROR",
      RESET_SELECTED_PICKUP_TIMES: "RESET_SELECTED_PICKUP_TIMES",
      SET_OFFERS: "SET_OFFERS",
      RESET_LAST_GAP_SEEN: "RESET_LAST_GAP_SEEN",
      SET_ANY_CAR: "SET_ANY_CAR",
    }),

    decideMdGivenName(len) {
      if (len < 5) {
        return 2;
      } else if (len < 15) {
        return 3;
      } else if (len < 20) {
        return 4;
      } else if (len < 25) {
        return 5;
      } else if (len < 30) {
        return 6;
      } else {
        return 7;
      }
    },
    decideSmGivenName(len) {
      if (len < 5) {
        return 2;
      } else if (len < 15) {
        return 3;
      } else if (len < 20) {
        return 4;
      } else if (len < 22) {
        return 5;
      } else if (len < 27) {
        return 6;
      } else {
        return 7;
      }
    },
    decideColsGivenName(len) {
      if (len < 5) {
        return 4;
      } else if (len < 15) {
        return 5;
      } else if (len < 20) {
        return 7;
      } else if (len < 25) {
        return 9;
      } else if (len < 30) {
        return 10;
      } else if (len < 35) {
        return 11;
      } else {
        return 12;
      }
    },
    async navigateForward() {
      this.SAVE_SELECTION_FORM_DATA(this.SelectCarForm);

      if (this.isBookable) {
        await this.CHECK_AVAILABILITY();
        if (this.isAvailable) {
          router.navigateTo("details", { modelVariantId: this.SelectCarForm.modelVariantId });
        } else {
          if(this.selectionFormData.modelName == "* Beliebiges E-Auto"){
            this.SET_ANY_CAR(true)
          }
          router.navigateTo("alternatives");
        }
      } else {
        this.SET_OFFERS(null);
        // makes sure the modelId and belongsTo always are filled in case we want to create a lead
        var bookingData = {
          baseBooking: {
            modelId: this.selectionFormData.modelId,
            belongsTo: this.selectionFormData.belongsTo,
          }
        }
        await this.SAVE_SELECTION_FORM_DATA(bookingData);
        router.navigateTo("leadCustomNote");
      }
    },
    onForwardError(err) {
      if (!axios.isCancel(err)) {
        this.UPDATE_ERROR({headline: "availabilityCheckFailed", error: err});
        router.navigateTo("error");
      }
    },
    setUrlParamsFromForm() {
      const urlParams = {
        modell: this.SelectCarForm.modelId,
        modelName: this.SelectCarForm.modelName,
        site: this.SelectCarForm.siteId,
        equipmentLine: this.SelectCarForm.equipmentLineId,
        variant: this.SelectCarForm.modelVariantId,
        startDate: this.SelectCarForm.startDate,
        endDate: this.SelectCarForm.endDate,
      };

      setUrlParams(urlParams);
    },
    fillFormFromUrlOrStore() {
      var vehicleSearchParameters = [
        "site",
        "equipmentLine",
        "variant",
        "modell",
        "startDate",
        "endDate",
      ];
      if (
          vehicleSearchParameters.some((p) =>
              Object.keys(this.$route.query).includes(p)
          )
      ) {
        if (this.$route.query.site) {
          this.SelectCarForm.siteId = this.$route.query.site;
        }

        if (this.$route.query.equipmentLine) {
          this.SelectCarForm.equipmentLineId = this.$route.query.equipmentLine;
        }

        if (this.$route.query.variant) {
          this.SelectCarForm.modelVariantId = this.$route.query.variant;
        }

        if (this.$route.query.modell) {
          this.SelectCarForm.modelId = this.$route.query.modell;
        }
  
        if (this.$route.query.startDate && this.$route.query.endDate) {
          this.SelectCarForm.startDate = this.$route.query.startDate;
          this.SelectCarForm.endDate = this.$route.query.endDate;
        }
      } else {
        try {
          var storageModelId = sessionStorage.modelId;
          var storageStartDate = sessionStorage.startDate;
          var storageEndDate = sessionStorage.endDate;
        } catch (error) {
          console.error(error);
        }

        // load display-data from store:
        this.SelectCarForm.modelId =
            this.selectionFormData.modelId ?? storageModelId ?? "";
        this.SelectCarForm.siteId = this.selectionFormData.siteId ?? "";
        this.SelectCarForm.equipmentLineId =
            this.selectionFormData.equipmentLineId ?? "";
        this.SelectCarForm.modelVariantId =
            this.selectionFormData.modelVariantId ?? this.variants[0];
        this.SelectCarForm.startDate =
            this.selectionFormData.startDate ?? storageStartDate ?? "";
        this.SelectCarForm.endDate =
            this.selectionFormData.endDate ?? storageEndDate ?? "";
      }
    },
    async calcPrice() {
      if (
          this.SelectCarForm.modelId &&
          this.SelectCarForm.siteId &&
          this.SelectCarForm.startDate &&
          this.SelectCarForm.endDate
      ) {
        try {
          this.loading = true;
          this.alerts = [];
          var res = await axios.post(`/booking/calcPrice`, this.getCalcPriceData());

          if (res.data.alerts?.filter((x) => x.type != "info").length > 0) {
            // something's wrong, try again with default handover-times
            this.RESET_SELECTED_PICKUP_TIMES();
            res = await axios.post(`/booking/calcPrice`, this.getCalcPriceData());
          }

          this.UPDATE_CALCULATION_RESULTS(res.data);
          this.loading = false;
          this.alerts = res.data.alerts;
          this.isBookable = res.data.isBookable;

          if (this.autoSearch && this.isBookable) {
            this.proceed = true;
          }
          if (this.autoSearch && !this.isBookable) {
            this.autoSearch = false;
          }
        } catch (err) {
          if (!axios.isCancel(err)) {
            this.loading = false;
            this.UPDATE_ERROR({error: err});
            router.navigateTo("error");
          }
        }
      }
    },
    dateRangeChanged(_, oldVal) {
      if (oldVal) {
        this.RESET_SELECTED_PICKUP_TIMES();
      }
    },
    getCalcPriceData() {
      var data = {
        booking: {
          startDate: this.SelectCarForm.startDate,
          endDate: this.SelectCarForm.endDate,
          modelName: this.SelectCarForm.modelName,
          belongsTo: this.SelectCarForm.belongsTo,
          siteId: this.SelectCarForm.siteId,
          modelId: this.SelectCarForm.modelId,
        },
        bookingDetails: {
          handoverTimeId: this.bookingDetails.handoverTimeId,
          returnTimeId: this.bookingDetails.returnTimeId,
        },
      };

      if (this.SelectCarForm.modelVariantId) {
        data.booking.modelVariantId = this.SelectCarForm.modelVariantId;
      }

      if (this.SelectCarForm.equipmentLineId) {
        data.booking.equipmentLineId = this.SelectCarForm.equipmentLineId;
      }
      return data;
    },
    isMonthlyPrice() {
      var twoMonths = 56;
      if (this.calculationResults.bookingDays >= twoMonths){
        return true;
      }
      return false;
    },
  },
};
</script>

<style src="@/styles/pages/VehicleSearchPage.scss" lang="scss"></style>
