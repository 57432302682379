<template>
  <v-row>
    <v-col class="pb-5">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col v-if="confirmOrder" class="ml-8">
              {{ $t("termsAndConditions.confirm") }}
            </v-col>
            <v-col v-else-if="acceptedTermsAndConditionsOnly" class="ml-8">
              {{  $t("termsAndConditions.digitallyConfirm", [orderName]) }}
            </v-col>
            <v-col v-else class="ml-8">
              {{ $t("termsAndConditions.modelPictures") }}
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              class="pb-0 pt-0"
            >
              <v-checkbox
                v-model="termsOfServiceAccepted"
                dense
              >
                <template #label>
                  <div class="body-2">
                    <i18n path="termsAndConditions.checkBox.termsOfService">
                      <template #termsOfServiceLink>
                        <a
                          :href="$t('general.url.termsOfService')"
                          target="_blank"
                          @click.stop
                        >{{
                          $t("termsAndConditions.linkText.termsOfService")
                        }}</a>
                      </template>
                    </i18n>
                  </div>
                </template>
              </v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              class="pt-0 pb-0"
            >
              <v-checkbox
                v-model="privacyPolicyAccepted"
                dense
              >
                <template #label>
                  <div class="body-2">
                    <i18n path="termsAndConditions.checkBox.privacyPolicy">
                      <template #privacyPolicyLink>
                        <a
                          :href="$t('general.url.privacyPolicy')"
                          target="_blank"
                          @click.stop
                        >{{
                          $t("termsAndConditions.linkText.privacyPolicy")
                        }}</a>
                      </template>
                    </i18n>
                  </div>
                </template>
              </v-checkbox>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      termsOfServiceAccepted: false,
      privacyPolicyAccepted: false,
    };
  },
  props: {
    confirmOrder: {
      type: Boolean,
      default: false,
    },
    acceptedTermsAndConditionsOnly: {
      type: Boolean, 
      default: false,
    },
    orderName:{
      type: String,
      default: "",
    }
  },
  watch: {
    termsOfServiceAccepted() {
      this.validate();
    },
    privacyPolicyAccepted() {
      this.validate();
    },
  },
  methods: {
    validate() {
      var isValid = this.termsOfServiceAccepted && this.privacyPolicyAccepted;
      this.$emit("update:validation", isValid);
    },
  },
};
</script>