var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('FilterWithChips',{attrs:{"selection":_vm.getSiteNames(),"icon-one-name":"['fab', 'map-marker-alt']"},on:{"selected-option":_vm.handleSelectedOption}}),(_vm.availableLocations.size > 0)?_c('div',{staticClass:"pr-6 pl-6"},_vm._l((_vm.availableLocations),function([key, value]){return _c('div',{key:key},[_c('v-row',[_c('v-col',{staticClass:"pt-5"},[_c('h3',[_vm._v(" Standort "+_vm._s(key)+" ")])])],1),_vm._l((_vm.isExpanded[key]
            ? value
            : value.slice(0, 3)),function(gap,index){return _c('v-row',{key:index},[_c('v-col',{staticClass:"pt-0 pb-5 cursor-pointer",on:{"click":function($event){return _vm.bookGap(gap, index)}}},[_c('BookingOfferBox',{directives:[{name:"ripple",rawName:"v-ripple"}],key:index + gap.modelId,attrs:{"booking-summary":_vm.summary(
                  gap.startDate,
                  gap.endDate,
                  gap.siteId,
                  gap.modelId,
                  gap.modelVariantId
                ),"original-end-date":null,"price":gap.rentPrice,"price-on-sale":gap.discountPercentage,"booking-days":gap.bookingDays,"included-kms":gap.includedKms}})],1)],1)}),(!_vm.isExpanded[key] && value.length > 3)?_c('v-row',[_c('v-col',{staticClass:"pt-0"},[_c('div',{staticClass:"pt-6"},[_c('v-btn',{staticStyle:{"zIndex":"7"},attrs:{"block":""},on:{"click":function($event){return _vm.clickHandler(key)}}},[_vm._v(" "+_vm._s(_vm.$vuetify.breakpoint.width > 400 ? _vm.$t("rentGapsPage.showMore") : _vm.$t("rentGapsPage.more"))+" ")])],1)])],1):_vm._e()],2)}),0):_vm._e(),_c('div',{staticStyle:{"position":"fixed","top":"100px"},attrs:{"id":"recaptcha","data-size":"invisible","data-sitekey":_vm.captchaWebsiteKey}}),(_vm.availableLocations.size == 0)?_c('div',{staticClass:"pr-6 pl-6"},[_c('v-card',{staticClass:"no-options-available",attrs:{"subtitle":"The #1 Vue UI Library","width":"parent"}},[_c('v-card-text',{staticClass:"bg-surface-light pt-4",attrs:{"prepend-icon":"fa-map-marker"}},[_vm._v(" "+_vm._s(_vm.$t("rentGapsPage.noOptions"))+" ")])],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }