<template>
  <ModelBanner
    :model-to-display="modelToDisplay"
    open-live-booking-tool
  />
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import ModelBanner from "@/components/ui/ModelBanner.vue";
import { router } from "@/router";

export default {
  components: {
    ModelBanner,
  },
  data() {
    return {
      modelId: null,
    };
  },
  async beforeMount() {
    if (this.masterDataError) {
      this.UPDATE_ERROR({ error: "backend.noMasterData" });
      router.navigateTo("error");
    } else if (this.$route.query.modell) {
      this.modelId = this.$route.query.modell;
    }
  },
  computed: {
    ...mapGetters({
      models: "models",
      masterDataError: "masterDataError",
    }),
    modelToDisplay() {
      if (this.models && this.modelId) {
        return this.models.find((x) => x.modelId == this.modelId);
      } else {
        return {};
      }
    },
  },
  methods: {    
    ...mapMutations({
      UPDATE_ERROR: "UPDATE_ERROR",
    }),
  }
};
</script>
