import { render, staticRenderFns } from "./BookingOptionMobile.vue?vue&type=template&id=352d8774&scoped=true&"
import script from "./BookingOptionMobile.vue?vue&type=script&lang=js&"
export * from "./BookingOptionMobile.vue?vue&type=script&lang=js&"
import style0 from "../../../styles/components.scss?vue&type=style&index=0&prod&lang=scss&"
import style1 from "./BookingOptionMobile.vue?vue&type=style&index=1&id=352d8774&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "352d8774",
  null
  
)

export default component.exports