<template>
  <CenterContainer>
    <v-row>
      <v-col
        class="pb-5"
      >
        <v-card>
          <v-card-title
            class="font-weight-medium subtitle-2"
          >
            {{ $t("leadCustomNotePage.headline") }}
          </v-card-title>
          <v-card-subtitle>
            {{ $t("leadCustomNotePage.message") }}
          </v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>

    <BookingSummaryBox
      :booking-summary="selectionSummary"
      :booking-days="calculationResults.bookingDays"
      :display-price-and-free-km="false"
    />

    <v-row>
      <v-col
        class="pt-5 pb-0 customNote"
      >
        <v-textarea
          v-model="customNoteInput"
          solo
          maxlength="900"
          counter="900"
          name="input-7-4"
          :label="$t('leadCustomNotePage.textbox.customNote')"
        />

        <NavigationButtons
          :navigate-back-action="navigateBack"
          :navigate-forward-action="navigateForward"
        />
      </v-col>
    </v-row>
  </CenterContainer>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import CenterContainer from "@/components/ui/CenterContainer";
import NavigationButtons from "@/components/ui/NavigationButtons";
import BookingSummaryBox from "@/components/ui/BookingSummaryBox";
import { router } from "@/router";

export default {
  components: {
    BookingSummaryBox,
    CenterContainer,
    NavigationButtons,
  },
  data: () => ({
    customNoteInput: null,
  }),
  async beforeMount() {
    if (!this.selectionFormData) {
      router.navigateTo("vehicleSearch");
    } else {
      this.RESET_BOOKING_STATE();
      this.customNoteInput = this.customNote;
    }
  },
  computed: {
    ...mapGetters({
      customNote: "customNote",
      selectionFormData: "selectionFormData",
      calculationResults: "calculationResults",
    }),
    selectionSummary() {
      // omitt variant and equipment, because they are not part of the lead-data:
      const summaryData = {
        startDate: this.selectionFormData.startDate,
        endDate: this.selectionFormData.endDate,
        modelName: this.selectionFormData.modelName,
        siteName: this.selectionFormData.siteName,
        modelId: this.selectionFormData.modelId,
      };
      return summaryData;
    },
  },
  methods: {
    ...mapMutations({
      SET_CUSTOM_NOTE: "SET_CUSTOM_NOTE",
      RESET_BOOKING_STATE: "RESET_BOOKING_STATE",
    }),
    navigateBack() {
      router.navigateTo("alternatives");
    },
    navigateForward() {
      this.SET_CUSTOM_NOTE(this.customNoteInput);
      router.navigateTo("leadContact");
    },
  },
};
</script>
