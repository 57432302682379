<template>
  <div>
    <div
      v-for="option in seperatedOptionInNewArray"
      :key="option.name"
      
      >
      <DynamicBoxes v-if="isMobile()" @selected-option="handleSelectedOption" :optionTexts="bookingOption.selectables" :standardOption="option" :isMonthlyPrice="isMonthlyPrice" :initialSelection="initialSelection"/>
    </div>
    <div class="booking-options-mobile">
     <div v-if="!isMobile()" class="selectable-options-container">
      <div class="single-option-row">
        <div
          v-for="option in seperatedOptionInNewArray"
          :key="option.name"
          class="booking-options-card "
          :class="isActive(option.bookingOptionId)"
        >
        <div class="selectable-booking-options">
          <p class="selectables-text">
            {{ option.name }}
          </p>
        </div>
   
         <div class="selectable-booking-options-price-details">
          <p class="selectables-price">
            {{ $n(option.cost, 'currency') }}
          </p>

          <p class="selectables-details"  @click="toggleDescription(option.bookingOptionId)">
          mehr Details
          </p>
    
          <div :class="getDescription(option).className">
            {{ getDescription(option, false).description }}
          </div>
   
         </div>
   
         <v-col
           cols="12"
           sm="2"
           class="col-radio-button"
           @click="setActive(option.bookingOptionId)"
         >
           <input
             :id="option.bookingOptionId"
             v-model="selectedId"
             type="radio"
             :value="option.bookingOptionId"
             class="my-radio-input"
           >
           <label
             :for="option.bookingOptionId"
             class="my-radio-label"
           />
         </v-col>
       </div>
      </div>   
      <div v-if="!isMobile() && !bookingOption.isCheckbox" class="multiple-options-row">
      <div
          v-for="option in bookingOption.selectables"
          :key="option.name"
          class="booking-options-card"
          :class="isActive(option.bookingOptionId)"   
        >
          <div  class="selectable-booking-options">
            <p class="selectables-text">
              {{ option.name }}
            </p>
          </div>
    
          <div class="selectable-booking-options-price-details">
            
            <p v-if="isMonthlyPrice" class="selectables-price">
              {{ $n(option.monthlyCost, 'currency') }}
            </p>
            <p v-else class="selectables-price">
              {{ $n(option.cost, 'currency') }}
            </p>
            <span v-if="(option.monthlyCost == option.cost) && isMonthlyPrice">
              <span  class="clue">einmalig</span>
              <br>
            </span>
          <br>
            <p class="selectables-details"  @click="toggleDescription(option.bookingOptionId)">
          mehr Details
        </p>
    
        <div :class="getDescription(option).className">
          {{ getDescription(option, false).description }}
        </div>
    
        </div>
    
          <v-col
            cols="12"
            sm="2"
            class="col-radio-button"
            @click="setActive(option.bookingOptionId)"
          >
            <input
              :id="option.bookingOptionId"
              v-model="selectedId"
              type="radio"
              :value="option.bookingOptionId"
              class="my-radio-input"
            >
            <label
              :for="option.bookingOptionId"
              class="my-radio-label"
            />
          </v-col>
        </div>
      </div>
    </div>

     <div class="selectable-options-container">
        <div class="single-option-row">
   
       <v-card
          v-if="bookingOption.isCheckbox"
          class="mx-auto custom-card"
          :style="{color: selectedId ? 'white' : 'black', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.3)'}"
          width="99%"
          :color="selectedId ? '#00806B' : ''"
        >
          <div style="display: flex; align-items: center; height: 56px; margin-bottom: -8px;">
            <div class="cb-container"  :class="{ 'cb-container-checked': selectedId }">
            <input class="cb-checkbox" type="checkbox"   
              id="checkbox" 
              role="checkbox"
              v-model="selectedId"
              :true-value="bookingOption.selectables[0].bookingOptionId"
              :false-value="0" 
            >
            <div class="cb cb-tick">
              <div class="tick-wrapper">
                <span class="tick-r"></span>
                <span class="tick-b"></span>
              </div>
            </div>
          </div>
            <div class="custom-checkbox-label" style="flex: 1; display: flex; justify-content: space-between; align-items: center;">
              <div class="custom-checkbox-text">
                <span class="checkbox-option-text" id="checkbox-option-text-id" >{{ bookingOption.selectables[0].name }}</span>
              </div>
              <div class="custom-checkbox-details">
                <span v-if="isMonthlyPrice" class="checkbox-option-details">{{ formatCurrency(bookingOption.selectables[0].monthlyCost) }}</span>
                <span v-else class="checkbox-option-details">{{ formatCurrency(bookingOption.selectables[0].cost) }}</span>
                <br />
                <span class="checkbox-selectables-details"  @click="toggleDescription(bookingOption.selectables[0].bookingOptionId)">mehr Details</span>
              </div>
            </div>  
            <div :class="tooltipClass">
            {{ tooltipDescription }}
          </div>
          </div>
       </v-card>
   </div>
   </div>

   </div>
  </div>
    
  </template>
  
  <script>
  import DynamicBoxes from "@/components/ui/DynamicBoxes";

  export default { 
    components: {
      DynamicBoxes
    },
    props: {
      bookingOption: { type: Object },
      initialSelection: { type: Object },
      isMonthlyPrice: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        showTooltip: false,
        tooltipStyle: {},
        selectedId: null,
        clickedOptionId: null,
        seperatedOptionInNewArray: [],
        optionTexts: [],
        oldId: null,
      };
    },
    created() {
      // Call the method to manipulate the object when the component is created
      this.getSelectableOptionsText()
    },
    watch: {
      
      initialSelection(newValue) {
        if (newValue) {
          this.selectedId = newValue.bookingOptionId;
        }
      },
      selectedId(newValue, oldValue) {
        if (
          oldValue &&
          this.bookingOption.selectables.some(
            (x) => x.bookingOptionId == oldValue
          )
        ) {
          this.$emit("update:removeSelectedOptionId", oldValue);
        }
        if (newValue) {
          this.$emit("update:addSelectedOptionId", newValue);
        }
      },
    },
    async beforeMount() {
      this.selectedId = this.initialSelection?.bookingOptionId;
    },
    computed: {
      tooltipData() {
        return this.getDescription(this.bookingOption.selectables[0], true);
      },
      tooltipClass() {
        return this.tooltipData.className;
      },
      tooltipDescription() {
        return this.tooltipData.description;
      },
    },
    methods: {
      handleSelectedOption(deselect, value) {
        if (deselect){
          this.$emit("update:removeSelectedOptionId", value);
        }else{
          this.$emit("update:addSelectedOptionId", value);
        }
      },
      toggleDescription(optionId) {
        if (this.clickedOptionId !== null && this.clickedOptionId !== optionId) {
        this.showTooltip = false;
        // Clear the timeout for the previous option
        clearTimeout(this.tooltipTimeout);
      }
  
      this.showTooltip = !this.showTooltip;
      this.clickedOptionId = optionId;
  
      // Set a timeout to close the tooltip after given seconds
      this.tooltipTimeout = setTimeout(() => {
        this.showTooltip = false;
      }, 2000); 
    },
    getDescription(option, checkbox) {
      if (this.clickedOptionId === option.bookingOptionId && this.showTooltip) {
        return {
          description: option.description,
          className: checkbox===true ? 'custom-checkbox-tooltip' : 'custom-tooltip',
        };
        
      } else {
        return {
          description: '',
          className: '', // No class when tooltip is not shown
        };
      }
    },
      formatCurrency(bookingOptionCost) {
        return this.$n(bookingOptionCost, "currency")
      },
      isActive(optionId) {
         if(optionId === undefined) {
          return {'booking-option-default-active': this.selectedId === optionId}
         } else 
        return {
          'booking-option-active': this.selectedId === optionId
        }
      },
      getSelectableOptionsText() {
        if(this.bookingOption.isCheckbox) {
          this.bookingOption.selectables.forEach (it => {
              if(it.isMandatory){
                console.log("from  getSelectableOptionsText",it)
              }
          })
        }
        if(!this.bookingOption.isCheckbox) {
          var selectables = 
          { name: this.bookingOption.name, cost: 0, description: this.bookingOption.description };
        
        this.optionTexts.push(selectables)
        this.bookingOption.selectables.forEach (it => {
          
          this.optionTexts.push(it)
        })
  
        // Push the first object from the original array to the new array
        this.seperatedOptionInNewArray.push(this.optionTexts.shift());
  
         return this.optionTexts;
        }
      },
      setActive(optionId) {
        this.selectedId = optionId;
      },
      isNotMandatory(it){
        it.selectables.forEach(element => {
          if(element.isMandatory !== true){
            return true
          }
        }); },
      getOptionText(bookingOption) {
        var optionText = bookingOption.name;
  
        if (Object.prototype.hasOwnProperty.call(bookingOption, "cost")) {
          optionText +=this.$n(bookingOption.cost, "currency")
        }
        return optionText;
      },
      isMobile() {
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
          return true
        } else {
          return false
        }
      },
    },
  };
  </script>
  
  <style src="../../../styles/components.scss" lang="scss"></style>

  <style scoped>
    .clue{
      font-size: xx-small;
      padding: 0px 6px 6px 5px;
      height: 10px;
      position: absolute;
      bottom:20px;
      left: 1px;
    }
  </style>