<template>
  <CenterContainer style="max-width: 600px;">
    <v-row v-if="masterDataLoading">
      <v-col>
        <v-skeleton-loader type="image, list-item@2, actions" />
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col class="calendarmain">
        <BookingCalendar
          :range-data="{
            start: SelectCarForm.startDate,
            end: SelectCarForm.endDate,
          }"
          @setStartDate="SelectCarForm.startDate = $event"
          @setEndDate="SelectCarForm.endDate = $event"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <PriceBox
          v-if="!loading && price"
          :rent-price="price"
          :total-days="bookingDays"
          :is-from-price="true"
          :loading="loading"
          :full-width="true"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <Alerts
          :alerts="alerts"
          :full-width="true"
        />
      </v-col>
    </v-row>

    <v-row
      v-if="loading"
      dense
    >
      <v-col>
        <v-skeleton-loader
          class="pa-2"
          type="list-item"
        />
      </v-col>
    </v-row>

    <v-row
      v-if="models.length"
      dense
    >
      <v-col>
        <v-btn
          block
          class="white--text"
          color="teal darken-1"
          @click="availabiltyCheckButtonClicked"
        >
          {{ $t("priceEnquiryPage.toAvailabilityCheck") }}
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <InfoCard
          v-if="selectedModel.abo"
          :headline="
            $t('longTermRentalLink.headline', { carName: selectedModel.name })
          "
          icon-name="arrow-alt-circle-right"
          :message="$t('longTermRentalLink.message')"
          :on-click-action="redirectToNextmoveAbo"
        />
      </v-col>
    </v-row>
  </CenterContainer>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import BookingCalendar from "@/components/ui/Calendar";
import InfoCard from "@/components/ui/InfoCard";
import CenterContainer from "@/components/ui/CenterContainer";
import PriceBox from "@/components/ui/PriceBox";
import Alerts from "@/components/ui/Alerts";
import axios from "axios";
import { router } from "@/router";

export default {
  components: {
    BookingCalendar,
    InfoCard,
    PriceBox,
    CenterContainer,
    Alerts,
  },
  data() {
    return {
      selectedModel: {},
      price: 0,
      bookingDays: 0,
      alerts: [],
      SelectCarForm: {
        modelId: "",
        startDate: "",
        endDate: "",
      },
      loading: false,
    };
  },
  watch: {
    models() {
      this.fillFormFromUrl(); // when page was refreshed, fill form as soon as models are loaded
    },
    "SelectCarForm.modelId"(selectedModelId) {
      this.models.forEach((m) => {
        if (m.modelId === selectedModelId) {
          this.selectedModel = m;
        }
      });
    },
    SelectCarForm: {
      immediate: true,
      deep: true,
      handler() {
        this.calcPrice();
      },
    },
  },
  async beforeMount() {
    if (this.masterDataError) {
      this.UPDATE_ERROR({ error: "backend.noMasterData" });
      router.navigateTo("error");
    } else if (!this.masterDataLoading) {
      // do nothing when page was refreshed and models are not loaded yet
      this.fillFormFromUrl();
    }
  },
  computed: {
    ...mapGetters({
      models: "models",
      masterDataLoading: "masterDataLoading",
      masterDataError: "masterDataError",
    }),
  },
  methods: {
    ...mapMutations({
      UPDATE_ERROR: "UPDATE_ERROR",
    }),
    fillFormFromUrl() {
      if (Object.keys(this.$route.query).length) {
        if (this.$route.query.modell) {
          this.SelectCarForm.modelId = this.$route.query.modell;
        }

        if (this.$route.query.startDate && this.$route.query.endDate) {
          this.SelectCarForm.startDate = this.$route.query.startDate;
          this.SelectCarForm.endDate = this.$route.query.endDate;
        }
      }
    },
    async calcPrice() {
      if (
        this.SelectCarForm.modelId &&
        this.SelectCarForm.startDate &&
        this.SelectCarForm.endDate
      ) {
        let data = {
          belongsTo: this.SelectCarForm.belongsTo,
          modelId: this.SelectCarForm.modelId,
          startDate: this.SelectCarForm.startDate,
          endDate: this.SelectCarForm.endDate,
        };

        try {
          this.loading = true;
          this.alerts = [];
          const res = await axios.post(`/lead/priceInfo`, data);
          this.loading = false;
          this.alerts = res.data.alerts;
          this.price = res.data.rentPrice;
          this.bookingDays = res.data.bookingDays;
        } catch (err) {
          if (!axios.isCancel(err)) {
            this.loading = false;
            this.UPDATE_ERROR({ error: err });
            router.navigateTo("error");
          }
        }
      }
    },
    availabiltyCheckButtonClicked() {
      try {
        sessionStorage.modelId = this.SelectCarForm.modelId;
        sessionStorage.startDate = this.SelectCarForm.startDate;
        sessionStorage.endDate = this.SelectCarForm.endDate;
      } catch (error) {
        console.error(error);
      }
      window.open(this.$t("general.url.bookingTool"), "_parent");
    },
    redirectToNextmoveAbo() {
      window.open(this.$t("general.url.longTermRental"), "_parent");
    },
  },
};
</script>

<style src="@/styles/pages/VehicleSearchPage.scss" lang="scss"></style>
