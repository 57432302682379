<template>
  <div>
    <v-row
      v-for="(option2, index2) in bookingOptions"
      :key="'option-' + index2"
      class="booking-options mt-4 mb-3"
      dense
    >
      <v-col
        v-if="index2 === 1"
        cols="12"
      >
        <h4

          class="booking-option-title"
        >
          {{ $t('bookingDetailsPage.options.highlighted') }}
        </h4>
      </v-col>
      <v-col
        v-if="index2 === 2"
        cols="12"
      >
        <h4
          class="booking-option-title"
        >
          {{ $t('bookingDetailsPage.options.other') }}
        </h4>
      </v-col>
      <template v-for="(option, index) in option2">
        <v-col
          :key="'optionParent-' + index"
          cols="12"
          class="booking-option-column divider"
        >
          <!-- <div v-if="isMobile()">
          <BookingOption
            :key="'option-' + index"
            :initial-selection="getSelectionForOption(option)"
            :booking-option="option"
            class="booking-option"
            @update:addSelectedOptionId="
              $emit('update:addSelectedOptionId', $event)
            "
            @update:removeSelectedOptionId="
              $emit('update:removeSelectedOptionId', $event)
            "
          />
        </div> -->
          <div>
            <BookingOptionMobile
              :isMonthlyPrice="isMonthlyPrice"
              :key="'option-' + index"
              :initial-selection="getSelectionForOption(option)"
              :booking-option="option"
              class="booking-option"
              @update:addSelectedOptionId="
                $emit('update:addSelectedOptionId', $event)
              "
              @update:removeSelectedOptionId="
                $emit('update:removeSelectedOptionId', $event)
              "
            />
          </div>
        </v-col>
      </template>
    </v-row>
  </div>
</template>

<script>
// import BookingOption from "./BookingOption";
import BookingOptionMobile from "./BookingOptionMobile";
import {mapGetters} from "vuex";

export default {
  components: {
    // BookingOption,
    BookingOptionMobile,
  },
  props: {
    selectedOptionIds: {type: Array},
    isMonthlyPrice: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      availableBookingOptions: "availableBookingOptions",
    }),
    bookingOptions() {
      if (!this.availableBookingOptions) {
        return [];
      }

      const options = this.availableBookingOptions
          .slice()
          .map((x) => {
            x.isCheckbox = x.selectables.length == 1;
            x.selectables.some((sel) => {
              if(sel.isMandatory === true){
                this.fixedChargingFee = sel.cost
          }
        })
            return x;
          })
          .filter((x) => !x.selectables.some((sel) => sel.isMandatory === true))

      let option_tiers = [[], [], []];
      options.forEach(item => {

        let isMandatory = false;
        // Check if mandatory

        // Check if highlighted
        if (isMandatory === false && item.isHighlighted === true) {
          option_tiers[1].push(item);
        } else if (isMandatory === false) {
          option_tiers[2].push(item);
        }
      });

      return option_tiers;
    },
  },
  methods: {
    getDescriptionForOption(bookingOption) {
      var selectable = bookingOption.isCheckbox
          ? bookingOption.selectables[0]
          : this.getSelectionForOption(bookingOption);

      if (!selectable) {
        return bookingOption.description;
      }

      return (
          "<div class='mb-1'><b>" +
          selectable.name +
          "</b> <span class='hint'>(" +
          this.$n(selectable.cost, "currency") +
          ")</span></div>" +
          selectable.description
      );
    },
    getSelectionForOption(bookingOption) {
      var mandatorySelectable = bookingOption.selectables.find(
          (x) => x.isMandatory
      );
      if (mandatorySelectable) {
        this.$emit(
            "update:addSelectedOptionId",
            mandatorySelectable.bookingOption
        );
        
        return mandatorySelectable;
      }

      return bookingOption.selectables.find((x) =>
          this.selectedOptionIds.includes(x.bookingOptionId)
      );
    },
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    }
  },
};
</script>