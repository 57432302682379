<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import { router } from "@/router";

export default {
  name: "App",

  async created() {
    document.title =
      process.env.VUE_APP_TITLE_PREFIX + this.$t("general.windowTitle");

    document.documentElement.setAttribute("lang", this.$i18n.locale);

    try {
      await this.INITIALIZE_APP();
    } catch (err) {
      this.UPDATE_ERROR({ error: err });
      router.navigateTo("error");
    }
  },

  methods: {
    ...mapActions({
      INITIALIZE_APP: "INITIALIZE_APP",
    }),
    ...mapMutations({
      UPDATE_ERROR: "UPDATE_ERROR",
    }),
  },
};
</script>

<style src="./styles/layout.scss" lang="scss"></style>
