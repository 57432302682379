<template>
  <CenterContainer>
    <v-row>
      <v-col
        class="pb-5"
      >
        <v-card>
          <v-card-title
            class="font-weight-medium subtitle-2"
          >
            {{ $t("leadContactPage.headline") }}
          </v-card-title>
          <v-card-subtitle>
            {{ $t("leadContactPage.message") }}
          </v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>

    <BookingSummaryBox
      :booking-summary="selectionSummary"
      :booking-days="calculationResults.bookingDays"
      :display-price-and-free-km="false"
    />

    <v-row>
      <v-col
        class="pb-5"
      >
        <ContactInput 
          :is-lead="true"
          @update:validation="isValid = $event"
          @update:loading="loading = $event"
        />

        <NavigationButtons
          :navigate-back-action="navigateBack"
          :navigate-forward-action="navigateForward"
          :forward-enabled="isValid && !loading"
          :error-action="onForwardError"
          :forward-text-key="loading ? 'contactForm.button.loading' : 'general.button.forward'"
        />
      </v-col>
    </v-row>
  </CenterContainer>
</template>

<script>
import ContactInput from "@/components/ui/Contact/ContactInput";
import CenterContainer from "@/components/ui/CenterContainer";
import NavigationButtons from "@/components/ui/NavigationButtons";
import BookingSummaryBox from "@/components/ui/BookingSummaryBox";
import { mapMutations, mapGetters } from "vuex";
import { router } from "@/router";

export default {
  components: {
    ContactInput,
    CenterContainer,
    NavigationButtons,
    BookingSummaryBox,
  },
  computed: {
    ...mapGetters({
      selectionFormData: "selectionFormData",
      calculationResults: "calculationResults",
    }),
    selectionSummary() {
      // omitt variant and equipment, because they are not part of the lead-data:
      const summaryData = {
        startDate: this.selectionFormData.startDate,
        endDate: this.selectionFormData.endDate,
        modelName: this.selectionFormData.modelName,
        siteName: this.selectionFormData.siteName,
        modelId: this.selectionFormData.modelId,
      };
      return summaryData;
    },
  },
  data: () => ({
    isValid: false,
    loading: false,
  }),
  async beforeMount() {
    if (!this.selectionFormData) {
      router.navigateTo("vehicleSearch");
    }
  },
  methods: {
    ...mapMutations({
      UPDATE_ERROR: "UPDATE_ERROR",
    }),
    navigateBack() {
      router.navigateTo("leadCustomNote");
    },
    async navigateForward() {
      router.navigateTo("leadSubmission");
    },
    onForwardError(err) {
      this.UPDATE_ERROR({ error: err, buttonRoute: "leadContact" });
      router.navigateTo("error");
    },
  },
};
</script>
