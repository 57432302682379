<template>
  <div class="booking-summary-box">
    <v-row>
      <v-col class="pb-5">
        <v-card
          v-if="bookingSummary"
          class="summary"
        >
          <v-card-text>
            <v-row>
              <v-col
                :cols="$vuetify.breakpoint.width > 600 ? 9 : 12"
                class="pt-0 pb-0"
              >
                <div class="body-2">
                  <p class="box-headline">
                    <span class="heading">{{ chosenCarName }}</span>
                  </p>
                  <p v-if="bookingSummary.orderName != null" class="mb-2">
                    {{ $t("bookingSummaryBox.orderName") }}:
                    <strong>{{ bookingSummary.orderName }}</strong>
                  </p>
                  <p class="mb-0">
                    <span v-if="bookingDays > 0">
                      <strong>{{
                        $tc("bookingSummaryBox.days", bookingDays, {
                          days: bookingDays,
                        })
                      }},
                      </strong>
                    </span>
                    <span>
                      {{ $t("bookingSummaryBox.from") }}
                      <strong>
                        <DateDisplay :date="bookingSummary.startDate" />
                      </strong>
                      {{ $t("bookingSummaryBox.until") }}
                      <strong>
                        <DateDisplay :date="bookingSummary.endDate" />
                      </strong>
                    </span>
                  </p>
                  <p v-if="bookingSummary.orderName == null" class="mb-2">
                    {{ $t("bookingSummaryBox.handoverAndReturn") }}:
                    <strong>{{ bookingSummary.siteName }}</strong>
                  </p>
                  <p class="mb-0">
                    <span v-if="pricePerAdditionalKm">{{
                      $t("bookingSummaryBox.additionalDistance", [
                        $n(pricePerAdditionalKm, "currency"),
                      ])
                    }}</span>
                  </p>
                  <p class="mb-0">
                    <span v-if="deductible != null">
                      {{
                        $t("bookingSummaryBox.deductible", [
                          $n(deductible, "currency"),
                        ])
                      }}
                    </span>
                  </p>
                  <p class="mb-0">
                    <span v-if="deposit != null">
                      {{
                        $t("bookingSummaryBox.deposit", [
                          $n(deposit, "currency"),
                        ])
                      }}
                    </span>
                  </p>
                  <p v-for=" option in getCompulsoryItems()" v-bind:key="option.name" class="mb-2">
                    <span>
                      {{option.name}} {{ $n(option.cost, "currency")}}
                      <font-awesome-icon
                      icon="info-circle"
                      style="padding-left: 5px; font-size: 15px;"
                      size="1x"
                      @click="toggleDescription(option.bookingOptionId)"
                    />
                    <div :class="getDescription(option).className">
                      {{ getDescription(option).description }} 
                    </div>
                    </span>
                  </p>
                  <p class="mb-1">
                    <span v-if="isMonthlyPrice()">
                      <strong v-if="currentRouteName == 'details'"><span>
                        {{
                        $t("bookingSummaryBox.monthlyPriceInDetailsPage")
                      }}</span></strong>
                      <strong v-else-if="!(currentRouteName == 'leadCustomNote' || currentRouteName == 'leadContact' || currentRouteName == 'leadSubmission')"><span> {{
                        $t("bookingSummaryBox.monthlyPrice")
                      }}</span></strong>
                    </span>
                  </p>
                  <p class="mb-2">
                    <span v-if="displayPriceAndFreeKm && price">
                      <i18n path="bookingSummaryBox.offerPriceKm">
                        <template #price>
                          <span v-if="isMonthlyPrice()">
                            <strong><span class="price">{{ $n(monthlyPrice, "currency") }}</span></strong>
                          </span>
                          <span v-else>
                            <strong><span class="price">{{ $n(price, "currency") }}</span></strong>
                          </span>
                         
                        </template>
                        <template #km>
                          <strong>
                            {{
                              $t("bookingSummaryBox.freeDistance", [
                              isMonthlyPrice() ? Math.round((calculationResults.includedKms / calculationResults.bookingDays)*month) : includedKms,
                              ])
                            }}</strong>
                        </template>
                      </i18n>
                    </span>
                  </p>
                </div>
              </v-col>

              <v-col
                cols="3"
                class="car-avatar mb-2"
              >
                <key-image :model-id="bookingSummary.modelId" style="background-size: 200px auto;" />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="booked-options">
      <v-col>
        <v-card
          v-if="showBookingOptions && (bookingDetails.bookingOptionIds.length - getCompulsoryItems().length) > 0"
          class="pb-4 pt-4"
        >
          <v-card-text class="pb-0">
            <BookedOptionLine
              v-for="optionId in bookingDetails.bookingOptionIds"
              :key="optionId"
              :option-id="optionId"
              :show-price="false"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import BookedOptionLine from "@/components/ui/BookedOptionLine";
import DateDisplay from "@/components/ui/DateDisplay";
import KeyImage from "./KeyImage";

export default {
  components: {
    BookedOptionLine,
    DateDisplay,
    KeyImage,
  },

  props: {
    bookingSummary: {
      type: Object,
    },
    orderName: {
      type: String,
      default: null,
    },
    price: {
      type: Number,
      default: null,
    },
    monthlyPrice: {
      type: Number,
      default: null,
    },
    bookingDays: {
      type: Number,
      default: null,
    },
    includedKms: {
      type: Number,
      default: null,
    },
    pricePerAdditionalKm: {
      type: Number,
      default: null,
    },
    deductible: {
      type: Number,
      default: null,
    },
    deposit: {
      type: Number,
      default: null,
    },
    showBookingOptions: {
      type: Boolean,
      default: false,
    },
    displayPriceAndFreeKm: {
      default: true,
    },
  },
  data() {
    return {
      showTooltip: false,
      selectedId: null,
      clickedOptionId: null,
      month: 28,
    };
  },
  computed: {
    ...mapGetters({
      bookingDetails: "bookingDetails",
      availableBookingOptions: "availableBookingOptions",
      calculationResults: "calculationResults"
    }),
    chosenCarName() {
      let str = this.bookingSummary.modelName;
      if (this.bookingSummary.modelVariantName)
        str += ", " + this.bookingSummary.modelVariantName;
      return str;
    },
    currentRouteName() {
      return this.$route.name;
    }
  },
  methods: {
  getCompulsoryItems() {
    var compulsoryItems = []
    this.availableBookingOptions.forEach((bookingOption) => {
      if(bookingOption.selectables[0].isMandatory){
        compulsoryItems.push(bookingOption.selectables[0])
      }
    })
    return compulsoryItems
  },
  toggleDescription(optionId) {
    if (this.clickedOptionId !== null && this.clickedOptionId !== optionId) {
      this.showTooltip = false;
    }
    this.showTooltip = !this.showTooltip;
    this.clickedOptionId = optionId;
    
    // Set a timeout to close the tooltip after 2 seconds
    this.tooltipTimeout = setTimeout(() => {
      this.showTooltip = false;
    }, 2000); 
  },
  getDescription(option) {
    if (this.clickedOptionId === option.bookingOptionId && this.showTooltip) {
      return {
        description: option.description,
        className: 'custom-charging-fee-tooltip',
      };
    } else {
      return {
        description: '',
        className: '', // No class when tooltip is not shown
      };
    }
  },
  isMonthlyPrice() {
    var twoMonths = 56;
    if (this.calculationResults.bookingDays >= twoMonths){
      return true;
    }
    return false;
  },
  }
};
</script>
