<template>
  <v-row
    class="price-box"
    :dense="fullWidth"
  >
    <v-col>
      <v-card
        class="mx-auto"
        max-width="100%"
        :loading="loading"
      >
        <!-- vehicle-rent bold -->
        <v-card-text
          v-if="(rentPrice || (!totalPrice && loading)) && isFromPrice"
          class="pb-0 total-price"
        >
          <div>
            <span class="float-left">{{ $t("priceBox.rentPrice") }}:</span>
          </div>
          <div>
            <span class="float-right">
              {{ $t("priceBox.from") }}
              {{
                $n(typeof rentPrice === "number" ? rentPrice : 0, "currency")
              }}
            </span>
          </div>
          <br>
        </v-card-text>

        <!-- vehicle-rent normal -->
        <v-card-text
          v-if="rentPrice && !isFromPrice"
          class="pb-0"
        >
          <v-row
            class="booked-option-line"
            dense
          >
            <v-col cols="9">
              {{ $t("priceBox.rentPrice") }}:
            </v-col>
            <v-col
              cols="3"
              class="option-price"
            >
              {{
                $n(typeof rentPrice === "number" ? rentPrice : 0, "currency")
              }}
            </v-col>
          </v-row>

          <BookedOptionLine
            v-for="optionId in bookingDetails.bookingOptionIds"
            v-show="showBookedOptions"
            :key="optionId"
            :option-id="optionId"
            :show-price="true"
          />
        </v-card-text>

        <!-- total -->
        <v-card-text
          v-if="totalPrice"
          class="total-price"
        >
          <hr v-if="rentPrice && totalPrice">
          <div>
            <span v-if="isMonthlyPrice == true" class="float-left">{{ $tc("priceBox.monthlyPrice", totalDays, {days: totalDays}) }}:</span>
            <span v-else class="float-left">{{ $tc("priceBox.totalPrice", totalDays, {days: totalDays}) }}:</span>
          </div>
          <div>
            <span class="float-right">
              {{ $n(totalPrice, "currency") }}
            </span>
          </div>
        </v-card-text>

        <!-- total per day -->
        <v-card-text class="price-per-day">
          <span v-if="totalDays > 0">
            {{
              $tc("priceBox.daysIncludingVat", totalDays, { days: totalDays })
            }}
          </span>
          <span class="float-right">
            {{
              $t("priceBox.pricePerDay", {
                pricePerDay: $n(pricePerDay, "currency"),
              })
            }}
          </span>
          <br>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import BookedOptionLine from "@/components/ui/BookedOptionLine";
import { mapGetters } from "vuex";

export default {
  components: {
    BookedOptionLine,
  },
  props: {
    rentPrice: {
      type: Number,
      required: false,
    },
    totalPrice: {
      type: Number,
      required: false,
    },
    isMonthlyPrice:{
      type: Boolean,
      default: false,
    },
    totalDays: {
      type: Number,
      required: true,
    },
    isFromPrice: {
      type: Boolean,
    },
    showBookedOptions: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      bookingDetails: "bookingDetails",
    }),
    pricePerDay() {
      return this.totalDays !== 0
        ? (
            (this.totalPrice ? this.totalPrice : this.rentPrice) /
            this.totalDays
          ).toFixed(2)
        : 0;
    },
  },
};
</script>
