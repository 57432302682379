<template>
  <v-row class="pl-0 pr-0 pb-0">
    <!-- back-button: -->
    <v-col
      v-if="navigateBackAction"
      cols="12"
      sm="6"
    >
      <v-btn
        :disabled="!backEnabled"
        block
        class="navigation-button"
        large
        exact
        @click="navigateBackAction"
      >
        {{ $t("general.button.back") }}
      </v-btn>
    </v-col>

    <!-- forward-button: -->
    <v-col
      v-if="navigateForwardAction"
      cols="12"
      :sm="navigateBackAction ? 6 : 12"
      order-sm="last"
      order="first"
    >
      <div
        id="recaptcha"
        data-size="invisible"
        :data-sitekey="captchaWebsiteKey"
      />
      <span @click="onDisabledButtonClick">
        <v-btn
          block
          large
          :loading="loading"
          class="navigation-button white--text"
          color="teal darken-1 pa-0"
          :disabled="!isForwardButtonEnabled"
          @click="onForwardClick"
        >
          {{ $t(forwardTextKey) }}
          <template #loader>
            <span class="custom-loader">
              <font-awesome-icon 
                icon="rotate"   
                size="2x"
              />
            </span>
          </template>
        </v-btn>
      </span>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
import { bus } from "@/main";
import { router } from "@/router";

export default {
  props: {
    navigateBackAction: {
      type: Function,
    },
    navigateForwardAction: {
      type: Function,
    },
    errorAction: {
      type: Function,
    },
    forwardEnabled: {
      type: Boolean,
      default: true,
    },
    backEnabled: {
      type: Boolean,
      default: true,
    },
    forwardTextKey: {
      type: String,
      default: "general.button.forward",
    },
    requiresCaptcha: {
      type: Boolean,
      default: false,
    },
    autoProceed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loadingCaptchaInput: false,
      loadingCaptchaVerification: false,
      loadingForwardAction: false,
      captchaRetryExecuted: false,
    };
  },
  watch: {
    autoProceed: {
      immediate: true,
      deep: true,
      async handler() {
        if (this.autoProceed) {
          this.executeForwardAction();
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      captchaSolved: "captchaSolved",
      captchaWebsiteKey: "captchaWebsiteKey",
    }),
    loading() {
      return (
        this.loadingCaptchaInput ||
        this.loadingCaptchaVerification ||
        this.loadingForwardAction
      );
    },
    isForwardButtonEnabled() {
      return !this.loading && this.forwardEnabled;
    },
  },
  methods: {
    ...mapActions({
      POST_CAPTCHA: "POST_CAPTCHA",
    }),
    ...mapMutations({
      UPDATE_ERROR: "UPDATE_ERROR",
    }),
    onForwardClick() {
      if (this.requiresCaptcha && !this.captchaSolved) {
        this.renderCaptcha();
      } else {
        this.executeForwardAction();
      }
    },
    async executeForwardAction() {
      this.loadingForwardAction = true;
      try {
        await this.navigateForwardAction();
      } catch (err) {
        // when the session was renewed due to a timeout, we have to level up the session once again:
        if (
          err &&
          err.response &&
          err.response.status == 403 &&
          !this.captchaRetryExecuted
        ) {
          this.renderCaptcha();
          this.captchaRetryExecuted = true;
        } else {
          if (this.errorAction) {
            this.errorAction(err);
          } else {
            router.navigateTo("error");
          }
        }
      }
      this.loadingForwardAction = false;
    },
    renderCaptcha() {
      this.loadingCaptchaInput = true;
      try {
        // "check" if reCaptcha is already/still rendered:
        window.grecaptcha.reset();
      } catch (err) {
        window.grecaptcha.render("recaptcha", {
          callback: this.verifyCaptcha,
          hl: this.$i18n.locale,
        });
      }

      // observe reCaptcha-window and reenable forward-button when it is closed:
      window.grecaptcha.execute().then(() =>
        setTimeout(() => {
          let closeWaiter = setInterval(
            function () {
              const reCaptchaIframes = document.querySelectorAll(
                'iframe[src*="recaptcha/api2/bframe"]'
              );
              const iframe = reCaptchaIframes[reCaptchaIframes.length - 1];
              if (
                iframe &&
                iframe.parentNode.parentNode.style.visibility == "hidden"
              ) {
                clearInterval(closeWaiter);
                this.loadingCaptchaInput = false;
              }
            }.bind(this),
            100
          );
        }, 2000)
      );
    },
    async verifyCaptcha(captchaToken) {
      this.loadingCaptchaVerification = true;
      try {
        const isCaptcha = await this.POST_CAPTCHA(captchaToken);
        if (isCaptcha) {
          this.executeForwardAction();
        }
      } catch (err) {
        this.UPDATE_ERROR({ headline: "captchaValidationFailed", error: err });
        router.navigateTo("error");
      }
      this.loadingCaptchaVerification = false;
    },
    onDisabledButtonClick() {
      if (!this.isForwardButtonEnabled) {
        bus.$emit("disabledForwardButtonClicked");
      }
    },
  },
};
</script>
