<template>
  <v-container
    fluid
    center
    class="pa-0"
    :class="fullWidth ? 'container-full-width' : 'container-with-margin'"
  >
    <div class="inner-container-center">
      <slot />
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      fullWidth: "fullWidth",
    }),
  },
};
</script>
