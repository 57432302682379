// adds or updates the given parameters into the browser-url
// existing url-params will be kept, keys with empty values will be removed
function setUrlParams(params) {
  const resultParams = [];
  if (window.location.hash) {
    const existingQuery = window.location.hash.split("?")[1];
    if (existingQuery) {
      let existingPairs = existingQuery.split("&");
      for (let i = 0; i < existingPairs.length; i++) {
        let parts = existingPairs[i].split("=");
        resultParams[parts[0]] = parts[1];
      }
    }
  }

  Object.assign(resultParams, params);

  let resultPairs = [];
  for (let key in resultParams) {
    if (resultParams[key]) {
      resultPairs.push(key + "=" + resultParams[key]);
    }
  }

  if (resultPairs.length > 0) {
    let result =
      window.location.hash.split("?")[0] + "?" + resultPairs.join("&");
    window.history.pushState(null, null, result);
  }
}

function parseBirthday(date) {
  if (!date) return "invalid";

  var day, month, year;

  if (date.includes(".")) {
    [day, month, year] = date.split(".");
  } else if (date.length >= 6) {
    day = date.substring(0, 2);
    month = date.substring(2, 4);
    year = date.substring(4, date.length);
  }

  if (!day || !month || !year || (year.length != 2 && year.length != 4)) {
    return "invalid";
  }

  const currYear = new Date().getFullYear().toString();
  const yearPad =
    year <= currYear.substring(2, 4)
      ? currYear.substring(0, 2)
      : currYear.substring(0, 2) - 1;

  return `${year.padStart(4, yearPad)}-${month.padStart(
    2,
    "0"
  )}-${day.padStart(2, "0")}`;
}

export { setUrlParams, parseBirthday };
