import VueRouter from "vue-router";
import store from "./store/store";
import { setUrlParams } from "./utils";

import ModelsOverviewPage from "./components/pages/landingPages/ModelsOverviewPage";
import SingleModelPage from "./components/pages/landingPages/SingleModelPage";
import VehicleSearchPage from "./components/pages/landingPages/VehicleSearchPage";
import PriceEnquiryPage from "./components/pages/landingPages/PriceEnquiryPage";
import BookingAlternativesPage from "./components/pages/alternatives/AlternativesPage";
import BookingDetailsPage from "./components/pages/booking/BookingDetailsPage";
import RentGapsPage from "./components/pages/rentGaps/RentGapsPage";
import BookingContactPage from "./components/pages/booking/BookingContactPage";
import BookingDriversPage from "./components/pages/booking/BookingDriversPage";
import BookingSubmissionPage from "./components/pages/booking/BookingSubmissionPage";
import ThankYouPage from "./components/pages/ThankYouPage";
import LeadContactPage from "./components/pages/lead/LeadContactPage";
import LeadCustomNotePage from "./components/pages/lead/LeadCustomNotePage";
import LeadSubmissionPage from "./components/pages/lead/LeadSubmissionPage";
import ErrorPage from "./components/pages/ErrorPage";
import BookingConditionsAcceptPage from "./components/pages/booking/BookingConditionsAcceptPage.vue"

const routes = [
  {
    path: "/",
    component: ModelsOverviewPage,
    name: "modelsOverview",
    meta: {
      userCanRefresh: true
    }
  },
  {
    path: "/banner",
    component: SingleModelPage,
    name: "singleModelPage",
    meta: {
      userCanRefresh: true
    }
  },
  {
    path: "/booking/search",
    component: VehicleSearchPage,
    name: "vehicleSearch",
    meta: {
      userCanRefresh: true
    }
  },

  {
    path: "/booking/price",
    component: PriceEnquiryPage,
    name: "priceEnquiry",
    meta: {
      userCanRefresh: true
    }
  },
  {
    path: "/booking/alternatives",
    component: BookingAlternativesPage,
    name: "alternatives",
    meta: {
      userCanRefresh: false
    }
  },
  {
    path: "/booking/gaps",
    component: RentGapsPage,
    name: "gaps",
    meta: {
      userCanRefresh: true
    }
  },
  {
    path: "/booking/details",
    name: "details",
    component: BookingDetailsPage,
    meta: {
      userCanRefresh: false
    },
    props: true
  },
  {
    path: "/booking/contact",
    name: "bookingContact",
    component: BookingContactPage,
    meta: {
      userCanRefresh: false
    }
  },
  {
    path: "/booking/drivers",
    name: "drivers",
    component: BookingDriversPage,
    meta: {
      userCanRefresh: false
    }
  },
  {
    path: "/booking/submission",
    name: "bookingSubmission",
    component: BookingSubmissionPage,
    meta: {
      userCanRefresh: false
    }
  },
  {
    path: "/accept",
    name: "accept",
    component: BookingConditionsAcceptPage,
    meta: {
      userCanRefresh: false
    }
  },
  {
    path: "/thankYou",
    name: "thankYou",
    component: ThankYouPage,
    meta: {
      userCanRefresh: false
    }
  },
  {
    path: "/error",
    name: "error",
    component: ErrorPage,
    meta: {
      userCanRefresh: false
    }
  },
  {
    path: "/lead/customNote",
    name: "leadCustomNote",
    component: LeadCustomNotePage,
    meta: {
      userCanRefresh: false
    }
  },
  {
    path: "/lead/contact",
    name: "leadContact",
    component: LeadContactPage,
    meta: {
      userCanRefresh: false
    }
  },
  {
    path: "/lead/submission",
    name: "leadSubmission",
    component: LeadSubmissionPage,
    meta: {
      userCanRefresh: false
    }
  }
];

export const router = new VueRouter({
  mode: "hash",
  routes
});

router.beforeEach((to, from, next) => {
  const fullWidth = to.query.fullWidth;
  if (fullWidth) {
    store.commit("SET_FULL_WIDTH");
  }

  // dev-feature to manually set/delete session-token:
  const token = to.query.token;
  if (token || token === null) {
    store.commit("UPDATE_SESSION", { token: token });
    store.commit("SET_CAPTCHA", !!token);
  }

  const urlReferral = to.query.referral;
  if (!store.getters.referral && urlReferral) {
    store.commit("SET_REFERRAL", { referral: urlReferral });
  }

  if (to.matched.some(record => !record.meta.userCanRefresh)) {
    if (from.fullPath === "/" && !from.name && to.name != "accept" && to.name != "details") {
      next({ name: "vehicleSearch" });
    } else if (to.name == "accept") {
      next();
    } else if (to.name == "details") {
      next();
    } else if (!to.params.internalNavigation) {
      console.log("aborted non-internal navigation");
      return false;
    } else {
      next();
    }
  } else if (to.name == "details") {
    next();
  }else {
    next();
  }

  window.scrollTo(0, 0);

  if (window.location !== window.parent.location) {
    window.parent.postMessage("scrollToTop", document.referrer);
  }
});

router.afterEach(() => {
  setUrlParams({ referral: store.getters.referral });
});

router.navigateTo = function(routeName, params) {
  if (!params) {
    params = {};
  }
  params.internalNavigation = true;

  return router.push({ name: routeName, params: params });
};
