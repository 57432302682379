<template>
  <CenterContainer>
    <BookingSummaryBox
      :booking-summary="baseBooking"
      :price="calculationResults.totalPrice"
      :monthlyPrice="calculationResults.totalMonthlyPrice"
      :booking-days="calculationResults.bookingDays"
      :included-kms="calculationResults.includedKms"
      :show-booking-options="true"
      :fixed-charging-fee= "getChargingFee()"
      />

    <ContactInput
      :is-lead="false"
      @update:validation="isValid = $event"
      @update:loading="loading = $event"
    />

    <NavigationButtons
      :navigate-back-action="navigateBack"
      :navigate-forward-action="navigateForward"
      :forward-enabled="isValid && !loading"
      :forward-text-key="loading ? 'contactForm.button.loading' : 'general.button.forward'"
    />
  </CenterContainer>
</template>

<script>
import ContactInput from "@/components/ui/Contact/ContactInput";
import BookingSummaryBox from "@/components/ui/BookingSummaryBox";
import NavigationButtons from "@/components/ui/NavigationButtons";
import CenterContainer from "@/components/ui/CenterContainer";
import { mapGetters } from "vuex";
import { router } from "@/router";

export default {
  components: {
    BookingSummaryBox,
    ContactInput,
    NavigationButtons,
    CenterContainer,
  },

  data: () => ({
    isValid: false,
    loading: false,
    fixedChargingFee: null,
  }),
  
  computed: {
    ...mapGetters({
      baseBooking: "baseBooking",
      bookingDetails: "bookingDetails",
      calculationResults: "calculationResults",
      availableBookingOptions: "availableBookingOptions",
    }),
  },
  async beforeMount() {
    if (!this.baseBooking || !this.bookingDetails) {
      router.navigateTo("vehicleSearch");
    }
  },
  
  methods: {
    navigateBack() {
        router.navigateTo("details");
    },
    navigateForward() {
      var nextPage = this.calculationResults.numberOfAdditionalDrivers
        ? "drivers"
        : "bookingSubmission";
      router.navigateTo(nextPage);
    },
    getChargingFee() {
      this.availableBookingOptions.forEach((bookingOption) => {
        if(bookingOption.selectables[0].bookingOptionId == "69989974-5a5e-ec11-8f8f-0022489b2cc3"){
          return bookingOption.selectables[0].cost
        }
      })
      return null
    },
  },
};
</script>