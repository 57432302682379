import { render, staticRenderFns } from "./LeadContactPage.vue?vue&type=template&id=4b2c647b&"
import script from "./LeadContactPage.vue?vue&type=script&lang=js&"
export * from "./LeadContactPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports