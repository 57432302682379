<template>
  <CenterContainer class="booking-submission-page">
    <BookingSummaryBox
      :booking-summary="baseBooking"
      :monthlyPrice="calculationResults.totalMonthlyPrice"
      :booking-days="calculationResults.bookingDays"
      :included-kms="calculationResults.includedKms"
      :price-per-additional-km="calculationResults.pricePerAdditionalKm"
      :price="calculationResults.totalPrice"
      :deductible="calculationResults.deductible"
      :deposit="calculationResults.deposit"
      :show-booking-options="false"
    />

    <v-row>
      <v-col>
        <PriceBox
          class="mt-7"
          :rent-price="calculationResults.rentPrice"
          :total-price="calculationResults.totalPrice"
          :total-days="calculationResults.bookingDays"
          :show-booked-options="true"
        />
      </v-col>
    </v-row>

    <v-form>
      <!-- Custom Note -->
            <v-textarea
              v-model="customNoteInput"
              solo
              maxlength="900"
              counter="900"
              name="input-7-5"
              :label="$t('bookingDetailsPage.textbox.customNote')"
            />
    </v-form>

    <TermsAndConditions @update:validation="isValid = $event" />

    <v-row>
      <v-col class="pb-5">
        <NavigationButtons
          :navigate-back-action="navigateBack"
          :back-enabled="!loading"
          :navigate-forward-action="navigateForward"
          :forward-enabled="isValid"
          :error-action="onForwardError"
          :requires-captcha="true"
          :forward-text-key="
            isValid
              ? 'general.button.submitBooking'
              : 'general.button.confirmationRequired'
          "
        />
      </v-col>
    </v-row>
  </CenterContainer>
</template>
<script>
import BookingSummaryBox from "@/components/ui/BookingSummaryBox";
import PriceBox from "@/components/ui/PriceBox";
import NavigationButtons from "@/components/ui/NavigationButtons";
import CenterContainer from "@/components/ui/CenterContainer";
import TermsAndConditions from "@/components/ui/TermsAndConditions";
import {mapGetters, mapActions, mapMutations} from "vuex";
import {router} from "@/router";

export default {
  components: {
    BookingSummaryBox,
    NavigationButtons,
    CenterContainer,
    TermsAndConditions,
    PriceBox,
  },
  computed: {
    ...mapGetters({
      gaps: "gaps",
      lastGapSeen: "lastGapSeen",
      baseBooking: "baseBooking",
      bookingDetails: "bookingDetails",
      contactData: "contactData",
      calculationResults: "calculationResults",
      customNote: "customNote",
    }),
  },
  async beforeMount() {
    if(this.customNote){
      this.customNoteInput = this.customNote
    }
    if (!this.baseBooking || !this.bookingDetails || !this.contactData) {
      router.navigateTo("vehicleSearch");
    }
  },
  data: () => ({
    loading: false,
    isValid: false,
  }),
  methods: {
    ...mapActions({
      SUBMIT_BOOKING: "SUBMIT_BOOKING",
    }),
    ...mapMutations({
      UPDATE_ERROR: "UPDATE_ERROR",
      SET_SUBMISSION_SUCCESS: "SET_SUBMISSION_SUCCESS",
      SET_CUSTOM_NOTE: "SET_CUSTOM_NOTE",
    }),
    async navigateBack() {
      var previousPage = this.calculationResults.numberOfAdditionalDrivers
          ? "drivers"
          : "bookingContact";
      router.navigateTo(previousPage);
    },
    async navigateForward() {
      this.loading = true;
      this.SET_CUSTOM_NOTE(this.customNoteInput);
      var isValidGap = false
      if (this.lastGapSeen.modelId != null) {
        isValidGap = true
      }
      const orderName = await this.SUBMIT_BOOKING(isValidGap)
      this.loading = false;
      this.SET_SUBMISSION_SUCCESS(true);
      router.navigateTo("thankYou", {orderName: orderName});
    },
    onForwardError(err) {
      if ( err?.response?.data == "backend.bookingFailed" && this.lastGapSeen.modelId != null){
        this.UPDATE_ERROR({
        headline: "bookingNoLongerAvailable",
        error: err,
        buttonRoute: "gaps",
        });
      } else{
        this.UPDATE_ERROR({
        headline: "bookingFailed",
        error: err,
        buttonRoute: "bookingContact",
        });
      }
      router.navigateTo("error");
    },
  },
};
</script>