<template>
  <v-form
    ref="contactForm"
    v-model="isValid"
    class="contact-input"
  >
    <CustomerType
      v-model="isCompany"
      class="pt-5 pb-1"
    />

    <v-row>
      <v-col
        v-if="isCompany"
        class="pb-0"
      >
        <v-text-field
          v-model="contactForm.companyName"
          outlined
          :label="$t('contactForm.companyName')"
          name="companyName"
          type="text"
          :rules="validateField('companyName')"
          :error-messages="$t(validationErrors.companyName)"
          required
          hide-details="auto"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col class="pb-0">
        <v-text-field
          v-model="contactForm.firstname"
          outlined
          :label="$t('contactForm.firstName')"
          name="firstname"
          :rules="validateField('firstname')"
          :error-messages="$t(validationErrors.firstname)"
          required
          hide-details="auto"
        />
      </v-col>
      <v-col>
        <v-text-field
          v-model="contactForm.lastname"
          outlined
          :label="$t('contactForm.lastName')"
          name="lastname"
          :rules="validateField('lastname')"
          :error-messages="$t(validationErrors.lastname)"
          required
          hide-details="auto"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col class="pb-0 pt-0">
        <v-text-field
          v-model="contactForm.street"
          outlined
          :label="$t('contactForm.street')"
          name="street"
          :rules="validateField('street')"
          :error-messages="$t(validationErrors.street)"
          required
          hide-details="auto"
        />
      </v-col>
      <v-col class="pt-0">
        <v-text-field
          v-model="contactForm.houseNumber"
          outlined
          :label="$t('contactForm.housenumber')"
          name="houseNumber"
          :rules="validateField('houseNumber')"
          :error-messages="$t(validationErrors.houseNumber)"
          required
          hide-details="auto"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col class="pb-0 pt-0">
        <v-text-field
          v-model="contactForm.postalcode"
          outlined
          name="postcode"
          :rules="validateField('postalcode')"
          :error-messages="$t(validationErrors.postalcode)"
          required
          hide-details="auto"
          :label="$t('contactForm.postcode')"
        />
      </v-col>
      <v-col class="pt-0">
        <v-text-field
          v-model="contactForm.city"
          outlined
          :label="$t('contactForm.city')"
          name="city"
          :rules="validateField('city')"
          :error-messages="$t(validationErrors.city)"
          hide-details="auto"
          required
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col class="country-input pt-0 pb-6">
        <v-select
          v-model="contactForm.countryCode"
          outlined
          :label="$t('contactForm.country')"
          name="country"
          :items="countries"
          :rules="validateField('country')"
          :error-messages="$t(validationErrors.countryCode)"
          required
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col class="pb-0 pt-0">
        <v-text-field
          v-model="contactForm.email"
          outlined
          :label="$t('contactForm.eMail')"
          name="email"
          :rules="validateField('email')"
          :error-messages="$t(validationErrors.email)"
          required
          hide-details="auto"
        />
      </v-col>
      <v-col class="pt-0">
        <v-text-field
          v-model="contactForm.phone"
          outlined
          :label="$t('contactForm.phone')"
          name="telefon"
          :rules="validateField('phone')"
          :error-messages="$t(validationErrors.phone)"
          required
          hide-details="auto"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col
        class="pt-0 pb-10"
        cols="6"
      >
        <BirthdayInput
          v-model="birthdayInput"
          :rules="validateField('birthday')"
          :error-message="
            $t(validationErrors.birthday, [birthdayPlaceholderData])
          "
          :missing-booking-option-id="missingBookingOptionId"
          name="birthday"
          @update:loading="loading = true"
        />
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import CustomerType from "./CustomerType";
import BirthdayInput from "./BirthdayInput.vue";
import { mapGetters, mapMutations } from "vuex";
import { parseBirthday } from "@/utils";
import axios from "axios";
import { bus } from "@/main";
import { router } from "@/router";

export default {
  components: {
    CustomerType,
    BirthdayInput,
  },
  props: {
    isLead: { type: Boolean, required: true },
  },

  data: () => ({
    isCompany: false,
    birthdayInput: null,
    contactForm: {
      firstname: "",
      lastname: "",
      email: "",
      phone: "",
      birthday: "",
      companyName: "",
      street: "",
      houseNumber: "",
      postalcode: "",
      city: "",
      countryCode: null,
    },
    isValid: false,
    validationErrors: {},
    validationRunning: false,
    validationQueued: false,
    birthdayPlaceholderData: "",
    missingBookingOptionId: "",
    typingTimer: null,
    waitingForValidationSince: null,
    loading: false,
  }),
  async beforeMount() {
    if (this.contactData) {
      this.contactForm = this.contactData;
      this.birthdayInput = this.contactData.birthdayUserInput;
      this.isCompany = this.contactData.companyName !== "";
    }
  },
  mounted() {
    bus.$on("disabledForwardButtonClicked", () => {
      this.$refs.contactForm.validate();
    });
    if (this.contactData) {
      this.$refs.contactForm.validate();
    }
  },
  computed: {
    ...mapGetters({
      contactData: "contactData",
      clientConfig: "clientConfig",
      baseBooking: "baseBooking",
      bookingDetails: "bookingDetails",
      selectionFormData: "selectionFormData",
    }),
    countries() {
      return this.clientConfig.countries.map((x) => {
        return {
          text: this.$t("general.country." + x.countryCode),
          value: x.countryCode,
        };
      });
    },
  },

  watch: {
    isCompany() {
      if (!this.isCompany) {
        this.contactForm.companyName = "";
      }
    },
    birthdayInput(newVal) {
      this.contactForm.birthdayUserInput = newVal;
      this.contactForm.birthday = parseBirthday(newVal);
    },
    isValid() {
      this.$emit("update:validation", this.isValid);
    },
    loading() {
      this.$emit("update:loading", this.loading);
    },
  },

  methods: {
    ...mapMutations({
      SET_CONTACT_DATA: "SET_CONTACT_DATA",
      UPDATE_ERROR: "UPDATE_ERROR",
    }),
    validateField(fieldName) {
      return [
        (v) => {
          let clientValidationError = this.validateClientSide(v);

          if (!clientValidationError) {
            this.loading = true;
            clearTimeout(this.typingTimer);
            if (!this.waitingForValidationSince) {
              this.waitingForValidationSince = new Date().getTime();
            }

            if (new Date().getTime() - this.waitingForValidationSince > 2000) {
              this.validateServerSide();
            } else {
              this.typingTimer = setTimeout(this.validateServerSide, 700);
            }
          } else if (this.validationErrors[fieldName]) {
            this.validationErrors[fieldName] = clientValidationError;
          }

          return !clientValidationError || this.$t(clientValidationError);
        },
      ];
    },
    validateServerSide() {
      if (this.validationRunning) {
        this.validationQueued = true;
      } else {
        this.validationRunning = true;
        this.validationQueued = false;
        this.waitingForValidationSince = null;
        
        var requestData = {
          belongsTo: this.selectionFormData.belongsTo, // In case lead has no organization assigned to it yet
          contact: this.contactForm,
          isLead: this.isLead,
        };
        if (this.isLead) {
          requestData.bookingStart = this.selectionFormData.startDate;
          requestData.modelId = this.selectionFormData.modelId;
        } else {
          requestData.bookingStart = this.baseBooking.startDate;
          requestData.modelId = this.baseBooking.modelId;
          requestData.variantId = this.baseBooking.modelVariantId;
          requestData.bookedOptionIds = this.bookingDetails.bookingOptionIds;
        }

        axios
          .post("/validation/contact", requestData)
          .then((result) => {
            this.validationErrors = result.data.contactData;
            this.birthdayPlaceholderData = result.data.birthdayPlaceholderData;
            this.missingBookingOptionId = result.data.missingBookingOptionId;
            this.loading = false;
            this.validatedValues = requestData.contact;
          })
          .catch((err) => {
            if (!axios.isCancel(err)) {
              this.UPDATE_ERROR({ error: err });
              router.navigateTo("error");
            }
          })
          .finally(() => {
            this.validationRunning = false;
            if (this.validationQueued) {
              this.validateServerSide();
            } else {
              this.SET_CONTACT_DATA(this.contactForm);
            }
          });
      }
    },
    validateClientSide(value) {
      return !value ? "general.validation.mandatory" : "";
    },
  },
  destroyed() {
    bus.$off("disabledForwardButtonClicked");
  },
};
</script>