<template>
  <CenterContainer v-if="this.retrievedBooking.TermsAndConditions" class="booking-submission-page">
    <BookingSummaryBox
      :booking-summary="baseBooking"
      :booking-days="null"
      :included-kms="retrievedBooking.CrmIncludedKms"
      :price-per-additional-km="null"
      :price="this.retrievedBooking.CrmTotalPrice"
      :show-booking-options="true"
      
    />
    <br>
    <v-form >

      <v-textarea
        v-model="customNoteInput"
        solo
        maxlength="900"
        counter="900"
        name="input-7-5"
        :label="$t('bookingDetailsPage.textbox.customNote')"
       
      />
    </v-form>

    <TermsAndConditions :confirmOrder="false" :acceptedTermsAndConditionsOnly="true" :orderName="baseBooking.orderName" @update:validation="isValid = $event" />

    <v-row>
      <v-col class="pb-5">
        <NavigationButtons
          :navigate-back-action="navigateBack"
          :back-enabled="!loading"
          :navigate-forward-action="navigateForward"
          :forward-enabled="isValid"
          :error-action="onForwardError"
          :requires-captcha="true"
          :forward-text-key="
            isValid
              ? 'general.button.submitConfirmationOfTermsAndConditions'
              : 'general.button.confirmationRequired'
          "
        />
      </v-col>
    </v-row>
  </CenterContainer>
  <div class="centered-notification" v-else-if="!this.retrievedBooking.TermsAndConditions && !this.loading">{{$t('bookingConditionsAcceptPage.nonActiveLink')}}</div>

</template>
<script>
import BookingSummaryBox from "@/components/ui/BookingSummaryBox";
import NavigationButtons from "@/components/ui/NavigationButtons";
import CenterContainer from "@/components/ui/CenterContainer";
import TermsAndConditions from "@/components/ui/TermsAndConditions";
import {mapGetters, mapActions, mapMutations} from "vuex";
import {router} from "@/router";
import axios from "axios";

export default {
  components: {
    BookingSummaryBox,
    NavigationButtons,
    CenterContainer,
    TermsAndConditions,
  },
  computed: {
    ...mapGetters({
      gaps: "gaps",
      lastGapSeen: "lastGapSeen",
      bookingDetails: "bookingDetails",
      contactData: "contactData",
      calculationResults: "calculationResults",
      models: "models"
    }),
  },
  async beforeMount() {
    this.loading = true;
    var data = {
        orderNumber: this.$route.query.id,
        belongsTo: this.$route.query.ps,
    };

    const res = await axios.post(`/booking/retrieveBooking`, data);

    this.retrievedBooking = res.data;
    this.baseBooking.endDate = this.retrievedBooking.EndTime;
    this.baseBooking.modelName = this.retrievedBooking.Model;
    this.baseBooking.modelId = this.findModelId(this.retrievedBooking.Model);
    this.baseBooking.startDate = this.retrievedBooking.StartTime;
    this.baseBooking.orderName = this.retrievedBooking.OrderName;
    this.loading = false;
  },
  data: () => ({
    loading: false,
    isValid: false,
    retrievedBooking: Object,
    baseBooking: {
      endDate: null,
      modelId: String,
      belongsTo: null,
      startDate: null,
      orderName: null,
    },
    customNoteInput: "",

  }),
  methods: {
    ...mapActions({
      SUBMIT_BOOKING: "SUBMIT_BOOKING",
    }),
    ...mapMutations({
      UPDATE_ERROR: "UPDATE_ERROR",
      SET_SUBMISSION_SUCCESS: "SET_SUBMISSION_SUCCESS",
      SET_CUSTOM_NOTE: "SET_CUSTOM_NOTE",
    }),
    async navigateBack() {
      var previousPage = this.calculationResults.numberOfAdditionalDrivers
          ? "drivers"
          : "bookingContact";
      router.navigateTo(previousPage);
    },
    async navigateForward() {
      this.loading = true;
      this.SET_CUSTOM_NOTE(this.customNoteInput);
      var data = {
        confirm: true,
        orderNumber: this.$route.query.id, 
        belongsTo: this.$route.query.ps,
        notes: this.customNoteInput,
      };
     const res = await axios.post(`/booking/confirmBookingTermsAndConditions`, data);
      this.SET_SUBMISSION_SUCCESS(true);
      router.navigateTo("thankYou", {orderName: res.data.orderName, acceptedTermsAndConditionsOnly: true});
    },
    onForwardError(err) {
      if ( err?.response?.data == "backend.bookingFailed" && this.lastGapSeen.modelId != null){
        this.UPDATE_ERROR({
        headline: "bookingNoLongerAvailable",
        error: err,
        buttonRoute: "gaps",
        });
      } else{
        this.UPDATE_ERROR({
        headline: "bookingFailed",
        error: err,
        buttonRoute: "bookingContact",
        });
      }
      router.navigateTo("error");
    },
    findModelId(modelName){
      const model = this.models.find((model) => model.name === modelName);
      return model?.modelId;
    },
  },
};
</script>

<style scoped>
.centered-notification{
  padding-left: auto;
  padding-right: auto;
  text-align: center;
  margin-top: auto;
  margin-bottom: auto;
  font-size: larger;
  font-weight: bold;
}
</style>