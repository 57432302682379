<template>
  <CenterContainer class="error-page">
    <v-row>
      <v-col>
        <v-card class="mx-auto">
          <v-card-text>
            <v-row>
              <v-col
                cols="12"
                class="box-headline"
              >
                {{ $t(getErrorKey()) }}
              </v-col>
              <v-col
                v-if="$store.state.error.error"
                cols="12"
              >
                {{ $t("errorPage.errorCaption") }}:
                <i>
                  <span v-if="$store.state.error.field">{{ $t('contactForm.' + $store.state.error.field) }}:</span>
                  {{ $t($store.state.error.error) }}
                </i>
              </v-col>
              <v-col cols="12">
                <i18n
                  path="errorPage.tryAgain"
                  tag="span"
                >
                  <template #emailLink>
                    <a :href="'mailto:' + $t('general.mail.support')">{{
                      $t("general.mail.support")
                    }}</a>
                  </template>
                </i18n>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <NavigationButtons
          :navigate-forward-action="onButtonClick"
          forward-text-key="general.button.back"
        />
      </v-col>
    </v-row>
  </CenterContainer>
</template>
<script>
import CenterContainer from "@/components/ui/CenterContainer";
import NavigationButtons from "@/components/ui/NavigationButtons";
import { router } from "@/router";

export default {
  components: {
    CenterContainer,
    NavigationButtons,
  },

  methods: {
    async onButtonClick() {
      // const routeName = this.$store.state.error.buttonRoute
      //   ? this.$store.state.error.buttonRoute
      //   : "vehicleSearch";
      // await router.navigateTo(routeName);
      await router.navigateTo("vehicleSearch");
    },
    getErrorKey() {
      const suffix = this.$store.state.error.headline
        ? this.$store.state.error.headline
        : "default";
      return "errorPage.headline." + suffix;
    },
  },
};
</script>
